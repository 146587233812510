import { Formik } from "formik";
import moment from "moment";
import * as momenttz from "moment-timezone";
import React, { ReactNode, useEffect, useRef, useState } from "react";
// eslint-disable-next-line
import {
  Button,
  Card as Card2,
  Col,
  Collapse,
  Container,
  Dropdown,
  DropdownButton,
  Form,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import * as Feather from "react-feather";
import { Frown, Trash2 } from "react-feather";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Link as ChakraLink,
  Tooltip as ChakraTooltip,
  useDisclosure,
  HStack,
  Modal,
} from "@chakra-ui/react";
import * as Yup from "yup";
import { useAppState } from "../../components/App/AppProvider";
import * as LocalStorage from "../../components/App/localStorage";
import { CustomFieldList } from "../../components/Forms/CustomFields/CustomField";
import {
  DateField,
  formatDateFromDBToReadOnly,
} from "../../components/Forms/Field/Date";
import { MultiSelect } from "../../components/Forms/Field/MultiSelect";
import { Select } from "../../components/Forms/Field/Select";
import { Text } from "../../components/Forms/Field/Text";
import { Textarea } from "../../components/Forms/Field/Textarea";
import { HelpText } from "../../components/Forms/HelpText/HelpText";
import { MandatoryIndicator } from "../../components/Forms/MandatoryIndicator/MandatoryIndicator";
import { CollapseChevron } from "../../components/UI/CollapseChevron/CollapseChevron";
import { Dialog } from "../../components/UI/Dialog/Dialog";
import { DocumentGenerator } from "../../components/UI/DocumentGenerator/DocumentGenerator";
import { TemplateDocumentGenerator } from "../../components/UI/DocumentGenerator/TemplatedDocumentGenerator";
import { Header } from "../../components/UI/Header/Header";
import { History } from "../../components/UI/History/History";
import { RefreshWorkflowMaxDataSingle } from "../../components/UI/Job/RefreshWorkflowMaxData";
import LegacyScreenContainer from "../../components/UI/LegacyScreenContainer";
import LegacyScreenWithSidebar from "../../components/UI/LegacyScreenWithSidebar";
import { Loading } from "../../components/UI/Loading/Loading";
import { NewObjectTypeButton } from "../../components/UI/NewObjectTypeButton";
import { Notes } from "../../components/UI/Notes/Notes";
import { NotesIcons } from "../../components/UI/Notes/NotesIcons";
import RequirementCloseButton from "../../components/UI/RequirementCloseButton";
import {
  objectStatusToVariant,
  Status,
} from "../../components/UI/Status/Status";
import { checkMandatoryCF } from "../../constants/customFieldType";
import * as ObjectTypeGroup from "../../constants/objectTypeGroup";
import { checkStoredValues } from "../../constants/storedValuesCheck";
import { urlRegex } from "../../constants/urlRegex";
import * as NoteType from "../../shared/constants/noteTypes";
import * as ObjectStatus from "../../shared/constants/objectStatus";
import * as PermissionCodes from "../../shared/constants/permission";
import * as Permissions from "../../shared/constants/permission";
import * as RecordStatus from "../../shared/constants/recordStatus";
import { PermissionCode } from "../../shared/definitions/access";
import { Note } from "../../shared/definitions/notes";
import * as Access from "../../utilities/access";
import { validateDate } from "../../utilities/formatDate";
import { del, get, post, put } from "../../utilities/request";
import { get as apiBelGet } from "../../utilities/apibelRequest";
import {
  text_stripHTML,
  text_truncate,
  toProperCase,
} from "../../utilities/strings";
import { getUrlSearchParam } from "../../utilities/url";
import useToast from "../../utilities/useToast";
import { Requirement, RequirementPageStatus } from "./Requirement.d";
import ConfirmationModal from "./ConfirmationModal";
import { CopyToClipboardIcon } from "../../constants/commonIcons";
import { RequirementManualSectionLinks } from "./RequirementManualSectionLinks";
import { Card } from "../../components/UI/Card/Card";

const timezones = momenttz.tz.names();

const validationSchema: any = Yup.lazy((values: any) => {
  if (values.ParentIDs.length === 0 && values.templateType) {
    // Only check Custom Fields if its not a template at top level
    return Yup.object().shape({
      RequirementName: Yup.string().min(1).required(`Name is required.`),
      RequirementResponsibleUserID: Yup.string()
        .min(1)
        .required("Responsible user is required."),
      ObjectTypeID: Yup.string().min(1).required("Type is required."),
    });
  }
  return Yup.object().shape({
    RequirementName: Yup.string().min(1).required(`Name is required.`),
    RequirementResponsibleUserID: Yup.string()
      .min(1)
      .required("Responsible user is required."),
    ObjectTypeID: Yup.string().min(1).required("Type is required."),
    CustomFields: Yup.array().of(
      Yup.object()
        .shape({})
        .when(
          "CustomFieldTypeColumnName",
          (CustomFieldTypeColumnName: string, schema: any) =>
            schema.test({
              name: "validateCustomFieldURL",
              test: (value: any) => {
                if (
                  value.CustomFieldTypeColumnName === "URL" &&
                  value.Value !== "http://" &&
                  value.Value !== "https://" &&
                  value.Value !== ""
                ) {
                  return urlRegex.test(value.Value);
                }
                return true;
              },
              message: ({ value }: any) =>
                `'${value.Label}' url field must be valid urls`,
              exclusive: false,
            }),
        ),
    ),
    ParentIDs:
      values.mandatoryParent && values.ObjectTypeGroupID
        ? Yup.string().required(
            `At least one Parent ${Access.objectTypeGroupIDToName(
              values.ObjectTypeGroupID,
            ).toLowerCase()} is required`,
          )
        : Yup.string(),
  });
});

const RelatedCard: any = (props: any) => {
  const { app, auth } = useAppState();
  const { displayToast } = useToast();
  const navigate = useNavigate();
  const [isCollapsed, setCollapsed] = React.useState<boolean>(
    props.object.CurrentRecords.length === 0,
  );
  const IconType: keyof typeof Feather = props.object.ObjectTypeIcon;
  const Icon = Feather[IconType];
  return (
    <section className="card">
      <Row>
        <Col>
          <h1>Related {props.object.ObjectTypeNamePlural} </h1>
        </Col>

        <Col style={{ textAlign: "right" }}>
          {Access.checkAccess(
            app.permissions_LEGACY,
            props.object.ObjectTypeGroupName?.toUpperCase(),
            Permissions.TypeUpdate,
            app.userInfo.userID === props.data.RequirementResponsibleUserID,
            props.object.ObjectTypeID,
          ) ? (
            <Dropdown
              id="dropdown-basic-button"
              alignRight
              onSelect={async (eventKey: any) => {
                const result = await put(
                  `requirementtree/relationship`,
                  {
                    parentID: props.data.RequirementID,
                    childID: eventKey,
                    RecordStatusID: props.templateType
                      ? RecordStatus.Template
                      : RecordStatus.Active,
                  },
                  auth,
                );
                if (result.status === 200) {
                  displayToast({
                    status: "success",
                    title: `${props.object.ObjectTypeName} added succesfully`,
                  });
                  window.location.reload();
                } else {
                  displayToast({
                    status: "error",
                    title: `Failed to add new ${props.object.ObjectTypeName} `,
                  });
                }
              }}>
              <Dropdown.Toggle variant="primary" id="dropdown-newobjecttype">
                {" "}
                {`Add Existing ${props.object.ObjectTypeName}`}
              </Dropdown.Toggle>
              <Dropdown.Menu className="newObjectDropdown">
                <div style={{ padding: "10px" }}>
                  <Text
                    name={`${props.object.ObjectTypeName}Search`}
                    value={
                      props.data.relatedObjectSearch[
                        `${props.object.ObjectTypeID}`
                      ]
                    }
                    readOnly={false}
                    onUpdate={(event: any) => {
                      const newSearches = props.data.relatedObjectSearch;
                      newSearches[`${props.object.ObjectTypeID}`] =
                        event.target.value;
                      props.setData({
                        ...props.data,
                        relatedObjectSearch: newSearches,
                      });
                    }}
                    placeHolder={`Search for ${props.object.ObjectTypeName}`}
                  />
                </div>
                <Dropdown.Divider />
                {props.object.RemainingRecords.filter((rec: any) =>
                  rec.RequirementName.toLowerCase().includes(
                    props.data.relatedObjectSearch[
                      `${props.object.ObjectTypeID}`
                    ]!.toLowerCase(),
                  ),
                ).map((record: any) => (
                  <Dropdown.Item
                    key={record.RequirementID}
                    eventKey={record.RequirementID}>
                    <Row>
                      <Col>
                        {record.RequirementReadableID.concat(
                          "-",
                          record.RequirementName,
                        )}
                      </Col>
                    </Row>
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          ) : null}
        </Col>
        {props.object.ObjectTypeGroupName !== "Job" ? (
          <Col sm={{ span: "auto" }}>
            {Access.checkAccess(
              app.permissions_LEGACY,
              props.object.ObjectTypeGroupName?.toUpperCase(),
              Permissions.TypeCreate,
              true,
              props.object.ObjectTypeID,
            ) ? (
              <Button
                onClick={() => {
                  navigate(
                    `${
                      props.templateType ? "/template" : ""
                    }/${props.object.ObjectTypeGroupName.toLowerCase()}/new?type=${
                      props.object.ObjectTypeID
                    }&relation=${props.data.RequirementID}`,
                  );
                }}>
                <Icon className="feather" size="16" />
                New {props.object.ObjectTypeName}
              </Button>
            ) : null}
          </Col>
        ) : null}
        <Col sm={{ span: "auto" }}>
          <CollapseChevron
            collapsed={isCollapsed}
            updateCollapsed={setCollapsed}
          />
        </Col>
      </Row>
      <Collapse in={!isCollapsed}>
        <Row>
          <Col>
            <hr />
            <Table responsive borderless>
              <thead>
                <tr>
                  {props.object.CurrentRecords.length !== 0
                    ? props.object.CurrentRecords[0].RegisterView.map(
                        (rec: any, index: number) => (
                          <th key={index}> {rec.colName} </th>
                        ),
                      )
                    : null}
                </tr>
              </thead>
              <tbody>
                {props.object.CurrentRecords.length !== 0 ? (
                  props.object.CurrentRecords.map((rec: any, index: number) => (
                    <tr key={index}>
                      {rec.RegisterView.map((col: any, innerIndex: number) => {
                        if (col.colLink && Array.isArray(col.colValue)) {
                          col.colValue.map((colValue: any, index: number) => {
                            if (
                              (rec.RecordStatusID === RecordStatus.Active &&
                                !props.templateType) ||
                              (rec.RecordStatusID === RecordStatus.Template &&
                                props.templateType)
                            ) {
                              return (
                                <td key={index}>
                                  <Link
                                    to={
                                      props.templateType
                                        ? `/template${col.colLink}`
                                        : col.colLink
                                    }>
                                    {colValue}
                                    {index === col.colValue.length - 1
                                      ? ""
                                      : ","}
                                  </Link>
                                </td>
                              );
                            }
                            return (
                              <td key={index}>
                                {colValue}
                                {index === col.colValue.length - 1 ? "" : ","}
                              </td>
                            );
                          });
                        } else if (col.colLink) {
                          if (
                            (rec.RecordStatusID === RecordStatus.Active &&
                              !props.templateType) ||
                            (rec.RecordStatusID === RecordStatus.Template &&
                              props.templateType)
                          ) {
                            return (
                              <td key={innerIndex}>
                                <Link
                                  to={
                                    props.templateType
                                      ? `/template${col.colLink}`
                                      : col.colLink
                                  }>
                                  {col.colValue}
                                </Link>
                              </td>
                            );
                          }
                          return <td key={innerIndex}> {col.colValue} </td>;
                        } else {
                          return col.colValue && col.colValue.length > 25 ? (
                            <OverlayTrigger
                              placement="right"
                              overlay={
                                <Tooltip id={`RegisterView${innerIndex}`}>
                                  <Textarea
                                    value={col.colValue}
                                    name={`RegisterView${innerIndex}`}
                                    readOnly
                                    richText
                                  />
                                </Tooltip>
                              }>
                              <td>
                                <Textarea
                                  value={text_truncate(col.colValue, 25)}
                                  name={`RegisterView${innerIndex}`}
                                  readOnly
                                  richText
                                />
                              </td>
                            </OverlayTrigger>
                          ) : (
                            <td key={innerIndex}>
                              <Textarea
                                value={
                                  col.CustomFieldTypeColumnName === "DATETIME"
                                    ? formatDateFromDBToReadOnly(col.colValue)
                                    : col.colValue
                                }
                                name={`RegisterView${innerIndex}`}
                                readOnly
                                richText
                              />
                            </td>
                          );
                        }
                      })}
                      {Access.checkAccess(
                        app.permissions_LEGACY,
                        props.data.ObjectTypeGroupName?.toUpperCase(),
                        Permissions.TypeUpdate,
                        app.userInfo.userID ===
                          props.data.RequirementResponsibleUserID,
                        props.data.ObjectTypeID,
                      ) ? (
                        <td
                          key={rec.RegisterView.length}
                          style={{ textAlign: "right" }}>
                          <button
                            style={{
                              backgroundColor: "#ffffff00",
                              border: "none",
                            }}
                            onClick={async () => {
                              // remove the relation between these two
                              const result = await del(
                                `requirementtree/remove/${props.data.RequirementID}/${rec.RequirementID}`,
                                auth,
                              );
                              if (result.status === 200) {
                                displayToast({
                                  status: "success",
                                  title: `${props.object.ObjectTypeName} removed succesfully`,
                                });
                                window.location.reload();
                              } else {
                                displayToast({
                                  status: "error",
                                  title: `Failed to remove ${props.object.ObjectTypeName} `,
                                });
                              }
                            }}>
                            <Trash2
                              style={{ color: "#d8d8d8" }}
                              className="feather"
                            />
                          </button>
                        </td>
                      ) : null}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>"No related records" </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Collapse>
    </section>
  );
};
const getOtherRequirements = (
  value: string,
  list: any[],
  relationships: any,
  templateType: any,
) => {
  const otherList = list.filter(
    (requirement: any) => requirement.RequirementID !== value,
  );
  // in template mode we need to remove that top level record that actually is a sub-record in order for it to not be choosen as a parent
  if (templateType) {
    const parentsBasedOnRelationships: any[] = [];
    otherList.forEach((rec: any) => {
      const check =
        relationships.find(
          (relationship: any) =>
            relationship.ObjectTypeID === rec.ObjectTypeID &&
            relationship.ObjectTypeRelationshipParent === 1,
        ) === undefined; // finds that sub-rec that is created as a top level for it to be removed from the final parents list
      if (!check) {
        parentsBasedOnRelationships.push(rec);
      }
    });
    return parentsBasedOnRelationships.filter(
      (requirement: any) => requirement.ParentIDs.length === 0,
    );
  }
  return otherList.filter(
    (requirement: any) => requirement.ParentIDs.length === 0,
  );
};

const checkRequirementChilds = (value: string, list: any[]) => {
  const childList = list.filter(
    (requirement: any) =>
      requirement.RequirementID === value &&
      requirement.objectStatusName === "Closed",
  );
  if (childList && childList.length > 0 && childList[0].ChildIDs.length > 0) {
    return true;
  }
  return false;
};
const checkRelationship = (
  ObjectTypes: any[],
  userPermissions: any,
  parents?: any[],
  relationships?: any[],
  parentType?: any,
) => {
  const newObjTypes: any[] = [];
  const type = parents && parents.length > 0 ? 0 : 1;
  ObjectTypes.forEach((obj: any) => {
    let check = false;
    if (relationships) {
      relationships.forEach((el: any) => {
        if (
          el.ObjectTypeID === obj.ObjectTypeID &&
          el.ObjectTypeGroupID === parentType &&
          el.ObjectTypeRelationshipParent === type
        ) {
          check = true;
        }
      });
    }
    if (
      check &&
      Access.checkAccess(
        userPermissions,
        Access.objectTypeGroupIDToName(obj.ObjectTypeGroupID),
        "CREATE",
        false,
        obj.ObjectTypeID,
      )
    ) {
      newObjTypes.push(obj);
    }
  });
  return newObjTypes;
};

const readonlymode = (
  pageStatus: string,
  parents: boolean,
  kids: boolean,
): boolean => {
  if (pageStatus === "New") {
    return false;
  }
  if (pageStatus === "Editing") {
    if (parents && !kids) {
      return false;
    }
    if (!parents && kids) {
      return true;
    }
    if (!parents && !kids) {
      return false;
    }
  } else {
    return true;
  }
  return false;
};

// this is to eliminate the subSection for when creating a record in templates that is set as a sub but you made is as a top
const displaySubSection = (templateMode: any, mandatoryParent: any) => {
  if (templateMode) {
    return !mandatoryParent;
  }
  return true;
};
const getChildRecords = (value: string, list: any[]): number => {
  let recordCount = 0;
  const thisRequirement = list.filter(
    (requirement: any) => requirement.RequirementID === value,
  );
  if (thisRequirement && thisRequirement.length > 0) {
    recordCount += thisRequirement[0].ActionIDs.length;
    recordCount += thisRequirement[0].ChildIDs.length;
    thisRequirement[0].ChildIDs.forEach((childID: string) => {
      const childRequirement = list.filter(
        (requirement: any) => requirement.RequirementID === childID,
      );
      if (childRequirement && childRequirement.length > 0) {
        recordCount += childRequirement[0].ActionIDs.length;
      }
    });
  }
  return recordCount;
};

const TemplateOptions = (
  templates: any[],
  setValues: any,
  templateID: any,
): ReactNode =>
  templates.map((template: any, index: number) => (
    <Form.Group as={Row} key={index} style={{ alignItems: "center" }}>
      <Form.Check
        name="TemplateRequirementID"
        id={template.RequirementID}
        type="radio"
        checked={templateID === template.RequirementID}
        onChange={(event: any) => {
          if (event.target.checked) {
            setValues("TemplateRequirementID", event.target.id);
          }
        }}
        label={template.RequirementName}
      />
      <span style={{ color: "#a2aab0" }}>&nbsp;&nbsp;&nbsp;</span>
      <HelpText
        id={template.RequirementID}
        text={template.RequirementDescription}
      />
    </Form.Group>
  ));

const formatUsers = (users: any[]): any[] =>
  users.map((user: any) => ({
    Name: `${user.UserFirstName} ${user.UserLastName}`,
    ID: user.UserID,
    Permission: true,
  }));

const getBreadcrumbs = (
  itemName: string,
  itemLink: string,
  parentID: string,
  parentlist: any[],
  display: string,
  pageStatus: RequirementPageStatus,
  template?: boolean,
) => {
  console.log("get crumbs");
  if (pageStatus === "New") {
    itemName = `New ${toProperCase(itemLink)}`;
  }

  if (parentID !== "") {
    const breadcrumb = [];
    let breadFlag = false;
    let nextParentID: string = parentID;

    // End link of item
    // breadcrumb.push({ label: itemName });

    const filterFunction = (item: any) => item.RequirementID === nextParentID;
    while (breadFlag === false) {
      const item = parentlist.find(filterFunction);
      if (item) {
        breadcrumb.push({
          label: item.RequirementName,
          link: `${template ? "/template" : ""}/${itemLink}/${
            item.RequirementID
          }${display}`,
        });
        if (item.ParentIDs[0] !== undefined) {
          nextParentID = item.ParentIDs[0];
        } else {
          breadFlag = true;
        }
      } else {
        breadFlag = true;
      }
    }

    // Base link of type
    if (!template) {
      breadcrumb.push({
        label: toProperCase(`${itemLink}s Dashboard`),
        link: `/${itemLink}?display=${itemLink}s`,
      });
    }

    return breadcrumb.reverse();
  }

  if (pageStatus === "New") {
    // creating new top level item with no parent
    return [
      {
        label: toProperCase(`${itemLink}s Dashboard`),
        link: `/${itemLink}?display=${itemLink}s`,
      },
      // { label: itemName },
    ];
  }

  // Top level already existing requirement
  return [
    {
      label: toProperCase(`${itemLink}s Dashboard`),
      link: `/${itemLink}?display=${itemLink}s`,
    },
  ];
  //const breadcrumbs = template
  //   ? [{ label: `${itemName}` }]
  //   : [
  //       { label: `${toProperCase(`${itemLink}`)}s`, link: `/${itemLink}` },
  //       // { label: `${itemName}` },
  //     ];
  // return breadcrumbs;
};

const getObjectTypeGroup = (thisObjectTypeGroupName: string) => {
  switch (thisObjectTypeGroupName) {
    case "issue":
      return ObjectTypeGroup.Issue;
    case "risk":
      return ObjectTypeGroup.Risk;
    case "job":
      return ObjectTypeGroup.Job;
    case "register":
      return ObjectTypeGroup.Register;
    default:
      return ObjectTypeGroup.Requirement;
  }
};

const JobOutOfSyncWarning = (props: { jobCustomFields: any[] }) => {
  const jobStartDate = props.jobCustomFields.find(
    (cf: any) => cf.Label === "Job: Start Date",
  );
  if (jobStartDate) {
    if (
      !(
        moment(jobStartDate.Value).diff(moment(), "months") > -2 &&
        moment(jobStartDate.Value).diff(moment(), "months") < 3
      )
    ) {
      return (
        <Row>
          <Col sm="auto">
            <p className="text-danger" style={{ marginBottom: "0px" }}>
              This job isn’t in the sync window so it’s not automatically being
              synced.Click “Refresh Job” to manually sync.
            </p>
          </Col>
        </Row>
      );
    }
  }
  return null;
};

const checkTemplateHasActions = (
  templateData: any[],
  selectedTemplateID: string,
) => {
  if (selectedTemplateID !== "") {
    return templateData.find(
      (template) => template.RequirementID === selectedTemplateID,
    ).HasActions;
  }
  return false;
};

const ScreensRequirement = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const { app, auth } = useAppState();
  const { displayToast } = useToast();

  const requirementID = params.requirementID || "";

  const [savedData, setSavedData] = useState(null);
  const [originalData, setOriginalData] = useState<Requirement>(null);
  const [pageStatus, setPageStatus] =
    useState<RequirementPageStatus>("Loading");
  const [data, setData] = useState<Requirement>(null);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [notes, setNotes] = useState({ isVisible: false, noteType: "" });
  const [parentID, setParentID] = useState<string>("");
  const [onDemandActionSearch, setOnDemandActionSearch] = useState<string>("");
  const thisObjectTypeGroupName =
    window.location.href.indexOf("template") > -1
      ? location.pathname.split("/")[2]
      : location.pathname.split("/")[1];
  // collapsible bits
  const [isDetailsCollapsed, collapseDetails] = useState<boolean>(false);
  const [isActionsCollapsed, collapseActions] = useState<boolean>(false);
  const [isChildrenCollapsed, collapseChildren] = useState<boolean>(false);
  const [isOnDemandActionsCollapsed, collapseOnDemandActions] =
    useState<boolean>(false);
  const templateType = window.location.href.indexOf("template") > -1;
  let parentObjectTypeGroupName = "";
  if (data && data.ParentObjectTypeGroup) {
    parentObjectTypeGroupName = data.ParentObjectTypeGroup.toLowerCase();
  }

  const formikRef = useRef();
  const confirmationModalState = useDisclosure({ defaultIsOpen: false });
  const thisObjectTypeGroup = getObjectTypeGroup(thisObjectTypeGroupName);
  const display = `?display=${thisObjectTypeGroupName}s`;
  const [errorMessage, seteErrorMessage] = useState<string>("");

  const deleteRequirement = async (data: any) => {
    const setDeleteMessage = (success: boolean) => {
      displayToast({
        status: success ? "success" : "error",
        title: success
          ? `${data.ObjectTypeName} deleted successfully`
          : `${data.ObjectTypeName} delete failed`,
      });
    };

    const redirect = () => {
      const path = templateType
        ? `/template/${thisObjectTypeGroupName}`
        : `/${thisObjectTypeGroupName}`;
      const navState = { hasChanged: true };
      const parent =
        parentID ||
        (data.ParentIDs.length > 0 ? data.ParentIDs[0].ParentID : null);

      // for sub-records
      if (parent) {
        navigate(
          {
            pathname: `${path}/${parent}`,
            search: display,
          },
          { state: navState },
        );
      }
      // for top-level records organised under an object type
      else if (
        data.ParentIDs.length < 1 &&
        (templateType || thisObjectTypeGroupName !== "requirement")
      ) {
        navigate(
          {
            pathname: `${path}`,
            search: `filter=${data.ObjectTypeID}`,
          },
          { state: navState },
        );
      }
      // for top level requirements/safety case
      else {
        navigate(
          {
            pathname: `${path}`,
            search: display,
          },
          { state: navState },
        );
      }
    };

    // remove the local storage after deletion
    window.localStorage.removeItem(`${window.location.href}`);

    const deleteResults = await del(
      `${thisObjectTypeGroupName}/${requirementID}${
        templateType ? "/template" : ""
      }${location.search}`,
      auth,
    );
    setDeleteMessage(deleteResults.status === 200);

    window.setTimeout(redirect, 3000);
  };

  const closeRequirement = async (data: any, close: boolean) => {
    post(`${thisObjectTypeGroupName}/${requirementID}`, {
      ...data,
      ObjectStatusID: close ? ObjectStatus.Closed : ObjectStatus.Neutral,
    });
    setData({
      ...data,
      ObjectStatusID: close ? ObjectStatus.Closed : ObjectStatus.Neutral,
      ObjectStatusName: close ? "Closed" : "Neutral",
    });
    setPageStatus("Ready");
  };

  useEffect(() => {
    const fetchRequirement = async () => {
      setPageStatus("Loading");
      const result: any = await get(
        `${thisObjectTypeGroupName}/${requirementID}${
          templateType ? "/template" : ""
        }${location.search}`,
      );
      const objectTypeAvailableFor = await apiBelGet(
        "settings/availableForByObjectType",
        {
          objectTypeID: result.data.ObjectTypeID,
        },
      );
      setParentID(getUrlSearchParam(location.search, "parentid") || "");

      if (result.status !== 200 || !result.data) {
        displayToast({
          status: "error",
          title: `Failed to retrieve ${thisObjectTypeGroupName}`,
        });
      } else {
        const isNew = requirementID === "new";
        const parentFromUrl = getUrlSearchParam(location.search, "parentid");
        if (
          result.data.RequirementID === "NotExist" ||
          (!isNew && !result.data.Permission)
        ) {
          // display error
          seteErrorMessage(
            result.data.RequirementID === "NotExist"
              ? `It looks like this ${thisObjectTypeGroupName} does not exist.`
              : `You don’t have permission to see the details of this ${thisObjectTypeGroupName}.`,
          );
          setPageStatus("Error");
        } else {
          // proceed
          // handle parent details
          const parent = parentFromUrl
            ? result.data.Requirements.find(
                (requirement: any) =>
                  requirement.RequirementID === parentFromUrl,
              )
            : "";
          if (parent) {
            result.data.ParentObjectTypeGroup = parent.ObjectTypeGroupName;
            result.data.Requirements = result.data.Requirements.filter(
              (item: any) =>
                item.ObjectTypeGroupName === result.data.ParentObjectTypeGroup,
            );
            parentObjectTypeGroupName = result.data.ParentObjectTypeGroup;

            if (isNew) {
              const parentObject = {
                ParentID: parentFromUrl,
                ParentName: parent.RequirementName,
                RequirementReadableID: parent.RequirementReadableID,
                ParentDescription: parent.RequirementDescription,
                ObjectTypeGroupName: result.data.ParentObjectTypeGroup,
                ObjectTypeIcon: parent.ObjectTypeIcon,
                Permission: parent.Permission,
              };
              result.data.ParentIDs.push(parentObject);
            }
            setParentID(result.data.ParentIDs[0].ParentID);
          }

          result.data.ObjectTypeID = isNew
            ? getUrlSearchParam(location.search, "type")
            : result.data.ObjectTypeID;
          result.data.AlertUsersIDs = result.data.AlertUsersIDs
            ? result.data.AlertUsersIDs
            : [];
          if (
            isNew &&
            result.data.ResponsibleUsers.find(
              (user: any) => user.UserID === app.attributes.userID,
            )
          ) {
            result.data.RequirementResponsibleUserID = app.attributes.userID;
          }
          result.data.CustomFields = result.data.CustomFields.map(
            (field: any) => ({
              ...field,
              Value: field.Value === null ? "" : field.Value,
            }),
          );
          result.data.templateType = templateType;
          result.data.ActionTimezone = result.data.ActionTimezone
            ? result.data.ActionTimezone
            : momenttz.tz.guess();
          if (result.data.ObjectTypeGroupID === undefined) {
            // make sure we have the ObjectTypeGroupID for validation reasons
            result.data.ObjectTypeGroupID = getObjectTypeGroup(
              thisObjectTypeGroupName,
            );
          }

          // fulds up sub requirements and actions if there are 0
          const objectTypeGroupID: PermissionCode =
            result.data.ObjectTypeGroupID;
          if (
            Access.objectTypeGroupIDToName(objectTypeGroupID) ===
            PermissionCodes.CodeRisk
          ) {
            result.data.controlSearch = "";
          }

          // Parent is mandatory if not register and if there is no relationship of type isParent
          const mandatoryParent =
            thisObjectTypeGroupName === "register"
              ? false
              : !objectTypeAvailableFor.some(
                  (relationship) => relationship.isParent,
                );

          collapseActions(result.data.Actions.length === 0);
          collapseChildren(
            result.data.Requirements.filter(
              (requirement: any) =>
                requirement.ParentIDs.indexOf(result.data.RequirementID) >= 0,
            ).length === 0,
          );
          collapseOnDemandActions(
            result &&
              result.data &&
              result.data.OnDemandActions &&
              result.data.OnDemandActions.length === 0,
          );
          // check if the new record is being created through a relation button
          if (isNew && window.location.href.indexOf("relation") > -1) {
            result.data.ObjectTypeTreeID = getUrlSearchParam(
              location.search,
              "relation",
            );
          }
          // enter the search values for related search
          if (
            result.data.ObjectTypeTree &&
            result.data.ObjectTypeTree.length !== 0
          ) {
            result.data.relatedObjectSearch = {};
            result.data.ObjectTypeTree.forEach((obj: any) => {
              result.data.relatedObjectSearch[`${obj.ObjectTypeID}`] = "";
            });
          }
          // here we will check if we have something in local storage, if we do, we will enter it as data and set the page on Editting mode. Otherwise we will persue as normal
          const storedValues = window.localStorage.getItem(
            `${window.location.href}`,
          );
          const parsedStoredValues = storedValues
            ? JSON.parse(storedValues)
            : "";
          if (
            !isNew &&
            parsedStoredValues &&
            checkStoredValues(parsedStoredValues, result.data, "Requirement") &&
            parsedStoredValues.RequirementID === result.data.RequirementID
          ) {
            setSavedData({ ...result.data, ...parsedStoredValues });
            setData({ ...result.data, mandatoryParent });
          } else if (
            isNew &&
            parsedStoredValues &&
            checkStoredValues(parsedStoredValues, result.data, "Requirement")
          ) {
            setSavedData(null);
            setData({ ...result.data, ...parsedStoredValues });
          } else {
            setSavedData(null);
            setData({ ...result.data, mandatoryParent });
          }
          setPageStatus(isNew ? "New" : "Ready");
        }
      }
    };

    if (auth.isLoggedIn) {
      fetchRequirement();
    }
  }, [requirementID, params]); // eslint-disable-line

  const isDataReady =
    data && pageStatus !== "Loading" && pageStatus !== "Error";

  // Title and breadcrumbs
  const pageTitle = isDataReady
    ? pageStatus !== "New"
      ? `${data.RequirementName}${
          data.ObjectStatusID === ObjectStatus.Closed ? " [CLOSED]" : ""
        }`
      : `New ${
          thisObjectTypeGroupName === "register"
            ? data.ObjectTypeName || toProperCase(thisObjectTypeGroupName)
            : toProperCase(thisObjectTypeGroupName)
        }`
    : "";
  const pageBreadcrumbs = isDataReady
    ? templateType
      ? [
          {
            label: `${toProperCase(thisObjectTypeGroupName)} Template`,
          },
        ]
      : getBreadcrumbs(
          data.RequirementName,
          thisObjectTypeGroupName,
          data.ParentIDs.length > 0 ? data.ParentIDs[0].ParentID : "",
          data.Requirements,
          display,
          pageStatus,
          templateType,
        )
    : [];
  const pageSubtitle = isDataReady ? data.RequirementReadableID : undefined;
  console.log({ pageBreadcrumbs });
  // TODO: Get notes working on this page
  // !templateType
  //                     ? NotesIcons({
  //                         userPermissions: app.permissions_LEGACY,
  //                         setNotes,
  //                         isNotesEnabled: data.ObjectTypeNotesEnabled,
  //                         isTimeNotesEnabled: data.ObjectTypeTimeNotesEnabled,
  //                         numberOfNotes: data.Notes.filter(
  //                           (note: any) => note.NoteTypeID === NoteType.Note,
  //                         ).length,
  //                         numberOfTimeNotes: data.Notes.filter(
  //                           (note: any) =>
  //                             note.NoteTypeID === NoteType.TimeNote,
  //                         ).length,
  //                       })
  //                     : null

  return (
    <LegacyScreenContainer
      pageTitle={pageTitle}
      pageSubtitle={pageSubtitle}
      breadcrumbs={pageBreadcrumbs}
      headerBottomContent={
        !templateType && pageStatus !== "Loading" && pageStatus !== "Error"
          ? NotesIcons({
              userPermissions: app.permissions_LEGACY,
              setNotes,
              isNotesEnabled: data.ObjectTypeNotesEnabled,
              isTimeNotesEnabled: data.ObjectTypeTimeNotesEnabled,
              numberOfNotes: data.Notes.filter(
                (note: any) => note.NoteTypeID === NoteType.Note,
              ).length,
              numberOfTimeNotes: data.Notes.filter(
                (note: any) => note.NoteTypeID === NoteType.TimeNote,
              ).length,
            })
          : null
      }>
      {data && pageStatus !== "Loading" && pageStatus !== "Error" ? (
        <>
          <section className="card">
            <Row>
              <Col sm="auto">
                <h1>Details </h1>
              </Col>
              <Col>
                <Status
                  variant={objectStatusToVariant(data.ObjectStatusID)}
                  text={data.ObjectStatusName}
                  tooltip={data.ObjectStatusDescription}
                />
              </Col>
              {pageStatus === "Ready" ? (
                <>
                  {thisObjectTypeGroupName === "job" ? (
                    <Col sm="auto">
                      <RefreshWorkflowMaxDataSingle
                        JobsWorkflowMaxID={data.RequirementReadableID}
                        JobModifiedTs={data.ModifiedTs}
                      />
                    </Col>
                  ) : null}

                  <Col sm="auto">
                    <DocumentGenerator data={data} />
                  </Col>

                  <Col sm="auto">
                    {thisObjectTypeGroupName !== "job" ? (
                      // eslint-disable-next-line react/jsx-no-useless-fragment
                      <>
                        {Access.checkAccess(
                          app.permissions_LEGACY,
                          Access.objectTypeGroupIDToName(
                            getObjectTypeGroup(thisObjectTypeGroupName),
                          ),
                          Permissions.TypeUpdate,
                          app.attributes.userID ===
                            data.RequirementResponsibleUserID,
                          data.ObjectTypeID,
                        ) ? (
                          savedData ? (
                            <OverlayTrigger
                              placement="auto"
                              overlay={
                                <Tooltip id="tooltip-unsaved-changes">
                                  You have unsaved changes
                                </Tooltip>
                              }>
                              <Button
                                type="button"
                                variant="outline-dark"
                                onClick={() => {
                                  setOriginalData(data);
                                  setData(savedData);
                                  setTimeout(
                                    () => setPageStatus("Editing"),
                                    20,
                                  );
                                }}>
                                <Feather.Edit2 className="feather" size="16" />
                                Edit
                              </Button>
                            </OverlayTrigger>
                          ) : (
                            <Button
                              type="button"
                              variant="outline-dark"
                              onClick={() => {
                                setPageStatus("Editing");
                              }}>
                              <Feather.Edit2 className="feather" size="16" />
                              Edit
                            </Button>
                          )
                        ) : (
                          <OverlayTrigger
                            placement="auto"
                            overlay={
                              <Tooltip id="tooltip-not-authorised-to-edit">
                                You do not have permission to edit this
                              </Tooltip>
                            }>
                            <Button
                              type="button"
                              variant="outline-dark"
                              className="disabled">
                              <Feather.Edit2 className="feather" size="16" />
                              Edit
                            </Button>
                          </OverlayTrigger>
                        )}
                      </>
                    ) : null}
                  </Col>
                </>
              ) : null}
              {pageStatus === "Editing" ? (
                <>
                  <Col sm="auto">
                    {!Access.checkAccess(
                      app.permissions_LEGACY,
                      Access.objectTypeGroupIDToName(
                        getObjectTypeGroup(thisObjectTypeGroupName),
                      ),
                      Permissions.TypeDelete,
                      app.attributes.userID ===
                        data.RequirementResponsibleUserID,
                      data.ObjectTypeID,
                    ) ? (
                      <OverlayTrigger
                        placement="auto"
                        overlay={
                          <Tooltip id="tooltip-cannot-respond-delete">
                            You do not have permission to delete this
                          </Tooltip>
                        }>
                        <Button variant="primary" className="disabled">
                          Delete
                        </Button>
                      </OverlayTrigger>
                    ) : data.Actions.length > 0 ||
                      checkRequirementChilds(
                        data.RequirementID,
                        data.Requirements,
                      ) ? (
                      <>
                        <Button
                          variant="outline-dark"
                          onClick={() => setDialogVisible(true)}>
                          Delete
                        </Button>
                        <Dialog
                          show={dialogVisible}
                          hide={() => setDialogVisible(false)}
                          title="Confirm delete"
                          body={`This record has ${getChildRecords(
                            data.RequirementID,
                            data.Requirements,
                          )} child record${
                            getChildRecords(
                              data.RequirementID,
                              data.Requirements,
                            ) > 1
                              ? "s"
                              : ""
                          } associated with it - are you sure you want to delete this record AND all of the child records?`}
                          confirm={() => deleteRequirement(data)}
                        />
                      </>
                    ) : (
                      <DropdownButton
                        id="dropdown-basic-button"
                        variant="outline-dark"
                        title="Delete">
                        <Dropdown.Item
                          as="button"
                          onClick={() => deleteRequirement(data)}>
                          Confirm delete
                        </Dropdown.Item>
                      </DropdownButton>
                    )}
                  </Col>
                  {!templateType ? (
                    <Col sm="auto">
                      <RequirementCloseButton
                        actions={data.Actions}
                        requirements={data.Requirements}
                        objectStatusID={data.ObjectStatusID}
                        objectTypeID={data.ObjectTypeID}
                        requirementResponsibleUserID={
                          data.RequirementResponsibleUserID
                        }
                        objectTypeGroupName={thisObjectTypeGroupName || ""}
                        handleClickClose={() =>
                          closeRequirement(
                            data,
                            data.ObjectStatusID !== ObjectStatus.Closed,
                          )
                        }
                        requirementID={data.RequirementID}
                      />
                    </Col>
                  ) : null}
                </>
              ) : null}
              <Col sm="auto">
                <CollapseChevron
                  collapsed={isDetailsCollapsed}
                  updateCollapsed={collapseDetails}
                />
              </Col>
            </Row>

            {thisObjectTypeGroupName === "job" && !templateType ? (
              <JobOutOfSyncWarning jobCustomFields={data.CustomFields} />
            ) : null}

            <Collapse in={!isDetailsCollapsed}>
              <Row>
                <Col>
                  <hr />
                  <Formik
                    enableReinitialize
                    initialValues={data}
                    validationSchema={validationSchema}
                    onSubmit={async (values, actions) => {
                      // we do not need this anymore as we want the sub-levels to have templates now
                      // if (values.ParentIDs.length >= 1) {
                      //     values.RecordStatusID = ''
                      // }
                      if (
                        !(values.ParentIDs.length === 0 && templateType) &&
                        checkMandatoryCF(values.CustomFields)
                      ) {
                        // Only check mandatory Custom Fields if its not a template at top level
                        actions.setSubmitting(false);
                      } else if (
                        values.RecordStatusID === "Template" &&
                        (values.TemplateRequirementID === "" ||
                          (checkTemplateHasActions(
                            values.TemplateData!,
                            values.TemplateRequirementID!,
                          ) &&
                            (values.ActionStartTs === undefined ||
                              !moment(values.ActionStartTs).isValid())))
                      ) {
                        actions.setSubmitting(false);
                      } else {
                        // Check to see requirement created from a template & if that template has actionStartTs
                        if (
                          values.RecordStatusID === "Template" &&
                          values.ActionStartTs
                        ) {
                          // IF there is an actionStartTs, we use validateDate to convert timezone for db entry
                          values = {
                            ...values,
                            ActionStartTs: validateDate(
                              new Date(values.ActionStartTs!),
                              values.ActionTimezone,
                            ),
                          };
                        }
                        setData(values);
                        setPageStatus("Submitting");
                        try {
                          values.AlertUsersIDs = values.AlertUsersIDs.map(
                            (user: any) => user.ID,
                          );
                          // modify what we pass up for the backend to deal with easier
                          values.CustomFields = values.CustomFields.map(
                            (field: any) => ({
                              ...field,
                              Value: field.Value === "" ? null : field.Value,
                            }),
                          );
                          values.CustomFields = values.CustomFields.map(
                            (field: any) => {
                              if (
                                field.CustomFieldTypeColumnName ===
                                  "DocumentID" ||
                                field.CustomFieldTypeColumnName ===
                                  "OneDriveDocumentID"
                              ) {
                                if (field.Value !== null) {
                                  field.Value = field.Value.map((doc: any) => {
                                    if (doc.status) {
                                      return { ...doc, buffer: {} };
                                    }
                                    return doc;
                                  });
                                }
                              }
                              return field;
                            },
                          );
                          // do the update
                          const results =
                            requirementID === "new"
                              ? await put(
                                  `${thisObjectTypeGroupName}${
                                    templateType ? "/template" : ""
                                  }`,
                                  values,
                                )
                              : await post(
                                  `${thisObjectTypeGroupName}/${requirementID}`,
                                  values,
                                );

                          // set success toast
                          displayToast({
                            status: "success",
                            title: `${results.data.ObjectTypeName} ${
                              requirementID === "new" ? "created" : "updated"
                            } successfully`,
                          });
                          // remove the local storage after submit
                          window.localStorage.removeItem(
                            `${window.location.href}`,
                          );
                          setSavedData(null);
                          // depending on our state redirect to a different page
                          const searchParams = getUrlSearchParam(
                            location.search,
                            "parentid",
                          )
                            ? `${display}&parentid=${getUrlSearchParam(
                                location.search,
                                "parentid",
                              )}`
                            : display;
                          if (!results.data.RequirementID) {
                            navigate("/requirement");
                          } else if (requirementID === "new") {
                            navigate(
                              {
                                pathname: `${
                                  templateType ? "/template" : ""
                                }/${thisObjectTypeGroupName}/${
                                  results.data.RequirementID
                                }`,
                                search: searchParams,
                              },
                              {
                                state: {
                                  hasChanged: true,
                                  display: display
                                    ? `${thisObjectTypeGroupName}s`
                                    : "",
                                },
                              },
                            );
                          } else {
                            navigate(
                              {
                                pathname: `${
                                  templateType ? "/template" : ""
                                }/${thisObjectTypeGroupName}/${
                                  data.RequirementID
                                }`,
                                search: searchParams,
                              },
                              {
                                state: {
                                  hasChanged: true,
                                  display: display
                                    ? `${thisObjectTypeGroupName}s`
                                    : "",
                                },
                              },
                            );
                          }
                          window.location.reload();
                          setPageStatus("Ready");
                        } catch (err) {
                          displayToast({
                            status: "error",
                            title: `Failed to ${
                              requirementID === "new" ? "create" : "update"
                            } ${values.ObjectTypeName} `,
                          });
                          setPageStatus(
                            requirementID === "new" ? "New" : "Editing",
                          );
                        }
                      }

                      actions.setSubmitting(false);
                    }}
                    render={(formikProps) => {
                      // here we save at every change in local storage
                      if (
                        (pageStatus === "New" || pageStatus === "Editing") &&
                        requirementID === formikProps.values.RequirementID
                      ) {
                        LocalStorage.saveInLocalStorage(
                          window.location.href,
                          formikProps,
                        );
                      }
                      return (
                        <Form onSubmit={formikProps.handleSubmit}>
                          {pageStatus === "New" ? (
                            formikProps.values.ObjectTypeTreeID ? (
                              <Form.Group as={Row}>
                                <Form.Label column sm="2">
                                  {toProperCase(thisObjectTypeGroupName)} Type
                                </Form.Label>
                                <Col sm="9">
                                  <Text
                                    value={formikProps.values.ObjectTypeName}
                                    name="RequirementObjectType"
                                    readOnly
                                  />
                                </Col>
                              </Form.Group>
                            ) : (
                              <Form.Group as={Row}>
                                <Form.Label column sm="2">
                                  {toProperCase(thisObjectTypeGroupName)} Type
                                </Form.Label>
                                <Col sm="2">
                                  <Form.Label>
                                    {formikProps.values.ObjectTypeName}
                                  </Form.Label>
                                </Col>
                              </Form.Group>
                            )
                          ) : (
                            <Form.Group as={Row}>
                              <Form.Label column sm="2">
                                {toProperCase(thisObjectTypeGroupName)} Type
                                <MandatoryIndicator
                                  ID={thisObjectTypeGroupName}
                                  pageStatus={pageStatus}
                                  elementType="field"
                                />
                              </Form.Label>
                              <Col sm="9">
                                <Text
                                  value={formikProps.values.ObjectTypeName}
                                  name="RequirementObjectType"
                                  readOnly
                                />
                              </Col>
                            </Form.Group>
                          )}
                          {thisObjectTypeGroup !== ObjectTypeGroup.Register ? (
                            <Form.Group as={Row}>
                              <Form.Label column sm="2">
                                Parent
                                {formikProps.values.mandatoryParent ? (
                                  <MandatoryIndicator
                                    ID="parent"
                                    pageStatus={pageStatus}
                                    elementType="field"
                                  />
                                ) : null}
                              </Form.Label>
                              <Col sm="9">
                                <MultiSelect
                                  onUpdate={(e: any) => {
                                    if (e !== null) {
                                      if (!templateType) {
                                        const newParents = e.map(
                                          (element: any) => {
                                            const parentDetails =
                                              formikProps.values.Requirements.find(
                                                (requirement: any) =>
                                                  requirement.RequirementID ===
                                                  element.value,
                                              );
                                            const parent = {
                                              ParentID: element.value,
                                              ParentName:
                                                element.label.substring(
                                                  element.label.lastIndexOf(
                                                    "-",
                                                  ) + 1,
                                                  element.label.length,
                                                ),
                                              RequirementReadableID:
                                                element.label.substring(
                                                  0,
                                                  element.label.lastIndexOf(
                                                    "-",
                                                  ),
                                                ),
                                              ParentDescription: parentDetails
                                                ? parentDetails.RequirementDescription
                                                : "",
                                              ObjectTypeGroupName: parentDetails
                                                ? parentDetails.ObjectTypeGroupName
                                                : "",
                                              ObjectTypeIcon: parentDetails
                                                ? parentDetails.ObjectTypeIcon
                                                : "",
                                              Permission: parentDetails
                                                ? parentDetails.Permission
                                                : false,
                                            };
                                            return parent;
                                          },
                                        );
                                        formikProps.setValues({
                                          ...formikProps.values,
                                          ParentIDs: newParents,
                                        });
                                      } else if (e.value === "") {
                                        const newParents: any[] = [];
                                        formikProps.setValues({
                                          ...formikProps.values,
                                          ParentIDs: newParents,
                                        });
                                      } else {
                                        const parentDetails =
                                          formikProps.values.Requirements.find(
                                            (requirement: any) =>
                                              requirement.RequirementID ===
                                              e.value,
                                          );
                                        const parent = [
                                          {
                                            ParentID: e.value,
                                            ParentName: e.label.substring(
                                              e.label.lastIndexOf("-") + 1,
                                              e.label.length,
                                            ),
                                            RequirementReadableID:
                                              e.label.substring(
                                                0,
                                                e.label.lastIndexOf("-") - 1,
                                              ),
                                            ParentDescription: parentDetails
                                              ? parentDetails.RequirementDescription
                                              : "",
                                            ObjectTypeGroupName: parentDetails
                                              ? parentDetails.ObjectTypeGroupName
                                              : "",
                                            ObjectTypeIcon: parentDetails
                                              ? parentDetails.ObjectTypeIcon
                                              : "",
                                            Permission: parentDetails
                                              ? parentDetails.Permission
                                              : false,
                                          },
                                        ];
                                        formikProps.setValues({
                                          ...formikProps.values,
                                          ParentIDs: parent,
                                        });
                                      }
                                    } else {
                                      const newParents: any[] = [];
                                      formikProps.setValues({
                                        ...formikProps.values,
                                        ParentIDs: newParents,
                                      });
                                    }
                                  }}
                                  name="RequirementID"
                                  value={
                                    pageStatus !== "New"
                                      ? formikProps.values.ParentIDs
                                      : formikProps.values.ParentIDs !=
                                        undefined
                                      ? formikProps.values.ParentIDs
                                      : []
                                  }
                                  unselectedOption={
                                    templateType
                                      ? "No Parent"
                                      : "Select a Value"
                                  }
                                  optionsList={getOtherRequirements(
                                    formikProps.values.RequirementID,
                                    formikProps.values.Requirements,
                                    formikProps.values.Relationships,
                                    templateType,
                                  )}
                                  optionsListID="RequirementID"
                                  optionsListValue="RequirementName"
                                  optionsListReadable="RequirementReadableID"
                                  optionsChild
                                  readOnly={readonlymode(
                                    pageStatus,
                                    data.ParentIDs.length >= 1,
                                    checkRequirementChilds(
                                      data.RequirementID,
                                      data.Requirements,
                                    ),
                                  )}
                                  readOnlyComponent={
                                    formikProps.values.ParentIDs
                                  }
                                  display={display}
                                  disabled={
                                    data.ObjectStatusID === ObjectStatus.Closed
                                  }
                                  template={templateType}
                                  action={false}
                                  seeMore={false}
                                />
                              </Col>
                            </Form.Group>
                          ) : null}
                          <Form.Group as={Row}>
                            <Form.Label column sm="2">
                              Name
                              <MandatoryIndicator
                                ID="name"
                                pageStatus={pageStatus}
                                elementType="field"
                              />
                            </Form.Label>
                            <Col sm="9">
                              <Text
                                value={formikProps.values.RequirementName}
                                name="RequirementName"
                                onUpdate={formikProps.handleChange}
                                readOnly={
                                  pageStatus !== "Editing" &&
                                  pageStatus !== "New"
                                }
                                disabled={
                                  data.ObjectStatusID === ObjectStatus.Closed
                                }
                                showCharacterLimit={
                                  pageStatus === "Editing" ||
                                  pageStatus === "New"
                                }
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row}>
                            <Form.Label column sm="2">
                              Description
                            </Form.Label>
                            <Col sm="9">
                              <Textarea
                                value={
                                  formikProps.values.RequirementDescription
                                }
                                name="RequirementDescription"
                                onUpdate={(val: any) => {
                                  formikProps.setFieldValue(
                                    "RequirementDescription",
                                    val,
                                  );
                                }}
                                readOnly={
                                  pageStatus !== "Editing" &&
                                  pageStatus !== "New"
                                }
                                disabled={
                                  data.ObjectStatusID === ObjectStatus.Closed
                                }
                                showCharacterLimit={
                                  pageStatus === "Editing" ||
                                  pageStatus === "New"
                                }
                                richText
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row}>
                            <Form.Label column sm="2">
                              Responsible User
                              <MandatoryIndicator
                                ID="responsible-user"
                                pageStatus={pageStatus}
                                elementType="field"
                              />
                            </Form.Label>
                            <Col sm="9">
                              <Select
                                onUpdate={(e: any) => {
                                  formikProps.setValues({
                                    ...formikProps.values,
                                    RequirementResponsibleUserID: e.value,
                                  });
                                }}
                                name="RequirementResponsibleUserID"
                                value={
                                  formikProps.values
                                    .RequirementResponsibleUserID
                                }
                                unselectedOption="Select a Value"
                                optionsList={
                                  formikProps.values.ResponsibleUsers
                                }
                                optionsChild={(listItem: any) => ({
                                  label: `${listItem.UserFirstName} ${listItem.UserLastName}`,
                                  value: listItem.UserID,
                                })}
                                readOnly={
                                  pageStatus !== "Editing" &&
                                  pageStatus !== "New"
                                }
                                readOnlyValue={
                                  formikProps.values
                                    .RequirementResponsibleUserName
                                }
                                disabled={
                                  data.ObjectStatusID === ObjectStatus.Closed
                                }
                              />
                            </Col>
                          </Form.Group>
                          {thisObjectTypeGroupName === "issue" &&
                          pageStatus === "New" &&
                          !templateType ? (
                            <Form.Group as={Row}>
                              <Form.Label column sm="2">
                                Alert right now
                              </Form.Label>
                              <Col sm="9">
                                <MultiSelect
                                  onUpdate={(e: any) =>
                                    e === null
                                      ? formikProps.setValues({
                                          ...formikProps.values,
                                          AlertUsersIDs: [],
                                        })
                                      : formikProps.setValues({
                                          ...formikProps.values,
                                          AlertUsersIDs: e.map((e: any) => ({
                                            ID: e.value,
                                            Name: e.label,
                                          })),
                                        })
                                  }
                                  name="AlertUsersIDs"
                                  value={formikProps.values.AlertUsersIDs}
                                  unselectedOption="Select a Value"
                                  optionsList={formatUsers(
                                    formikProps.values.ResponsibleUsers,
                                  )}
                                  optionsListValue="Name"
                                  optionsListID="ID"
                                  readOnly={false}
                                  disabled={
                                    data.ObjectStatusID === ObjectStatus.Closed
                                  }
                                />
                                <span className="form-label-small">
                                  Users will be notified via email upon creation
                                  of this record.
                                </span>
                              </Col>
                            </Form.Group>
                          ) : null}
                          {/* for non-Date custom fields, onUpdate parameters are: event, null, null */}
                          {/* for Date custom field, onUpdate parameters are: name, Date, event */}
                          {formikProps.values.ParentIDs.length === 0 &&
                          templateType ? null : (
                            <CustomFieldList
                              customFields={formikProps.values.CustomFields}
                              pageStatus={pageStatus}
                              disabled={
                                data.ObjectStatusID === ObjectStatus.Closed
                              }
                              onUpdate={(event: any, a: any, b: any) => {
                                const newFields =
                                  formikProps.values.CustomFields.map(
                                    (field: any) => {
                                      // for every custom field
                                      if (
                                        event.target !== undefined &&
                                        event.target.name ===
                                          field.CustomFieldID
                                      ) {
                                        return {
                                          ...field,
                                          Value: event.target.value,
                                        };
                                        // just for the uploader one
                                      }
                                      if (
                                        event.cfID === field.CustomFieldID &&
                                        a.fileList
                                      ) {
                                        return {
                                          ...field,
                                          Value: a.fileList,
                                        };
                                      }
                                      if (
                                        event.cfID === field.CustomFieldID &&
                                        a !== null
                                      ) {
                                        return { ...field, Value: a };
                                      }
                                      return field;
                                    },
                                  );
                                formikProps.setValues({
                                  ...formikProps.values,
                                  CustomFields: newFields,
                                });
                              }}
                              formError={!!formikProps.errors.CustomFields}
                            />
                          )}

                          {thisObjectTypeGroupName === "risk" ? (
                            <Form.Group as={Row}>
                              <Form.Label column sm="2">
                                Related Issues
                              </Form.Label>
                              <Col sm="9">
                                <MultiSelect
                                  onUpdate={(e: any) => {
                                    if (e !== null) {
                                      const newRisks = e.map((element: any) =>
                                        formikProps.values.Issues!.find(
                                          (issue: any) =>
                                            issue.RequirementID ===
                                            element.value,
                                        ),
                                      );
                                      formikProps.setValues({
                                        ...formikProps.values,
                                        RelatedRequirements: newRisks,
                                      });
                                    } else {
                                      const newRisks: any[] = [];
                                      formikProps.setValues({
                                        ...formikProps.values,
                                        RelatedRequirements: newRisks,
                                      });
                                    }
                                  }}
                                  name="RequirementID"
                                  value={
                                    formikProps.values.RelatedRequirements
                                      ? formikProps.values.RelatedRequirements
                                      : []
                                  }
                                  unselectedOption="No related Issues"
                                  optionsList={formikProps.values.Issues!}
                                  optionsChild
                                  readOnly={
                                    pageStatus !== "Editing" &&
                                    pageStatus !== "New"
                                  }
                                  optionsListID="RequirementID"
                                  optionsListValue="RequirementName"
                                  optionsListReadable="RequirementReadableID"
                                  // readOnlyValue={formikProps.values.RiskIDs ? formikProps.values.RiskIDs.map((risk: any) => risk.RiskName).join(', '): undefined}
                                  readOnlyComponent={
                                    formikProps.values.RelatedRequirements
                                      ? formikProps.values.RelatedRequirements.map(
                                          (req: any) => ({
                                            ...req,
                                            ObjectTypeGroupName: "Issue",
                                          }),
                                        )
                                      : []
                                  }
                                  display="?display=issues"
                                  disabled={
                                    data.ObjectStatusID === ObjectStatus.Closed
                                  }
                                  template={templateType}
                                  action={false}
                                  risk
                                />
                              </Col>
                            </Form.Group>
                          ) : null}
                          {thisObjectTypeGroupName === "issue" &&
                          !templateType ? (
                            <Form.Group as={Row}>
                              <Form.Label column sm="2">
                                Related Risks / Controls
                              </Form.Label>
                              <Col sm="9">
                                <MultiSelect
                                  onUpdate={(e: any) => {
                                    if (e !== null) {
                                      const newRisks = e.map((element: any) =>
                                        formikProps.values.Risks!.find(
                                          (risk: any) =>
                                            risk.RequirementID ===
                                            element.value,
                                        ),
                                      );
                                      formikProps.setValues({
                                        ...formikProps.values,
                                        RiskIDs: newRisks,
                                      });
                                    } else {
                                      const newRisks: any[] = [];
                                      formikProps.setValues({
                                        ...formikProps.values,
                                        RiskIDs: newRisks,
                                      });
                                    }
                                  }}
                                  name="RiskID"
                                  value={
                                    formikProps.values.RiskIDs !== undefined
                                      ? formikProps.values.RiskIDs
                                      : []
                                  }
                                  unselectedOption="No related Risk or Controls"
                                  optionsList={
                                    formikProps.values.Risks !== undefined
                                      ? formikProps.values.Risks
                                      : []
                                  }
                                  optionsChild
                                  readOnly={
                                    pageStatus !== "Editing" &&
                                    pageStatus !== "New"
                                  }
                                  // readOnlyValue={formikProps.values.RiskIDs ? formikProps.values.RiskIDs.map((risk: any) => risk.RiskName).join(', '): undefined}
                                  readOnlyComponent={
                                    formikProps.values.RiskIDs
                                      ? formikProps.values.RiskIDs.map(
                                          (risk: any) => ({
                                            ...risk,
                                            ObjectTypeGroupName: "Risk",
                                          }),
                                        )
                                      : []
                                  }
                                  display="?display=risks"
                                  optionsListID="RequirementID"
                                  optionsListValue="RequirementName"
                                  optionsListReadable="RequirementReadableID"
                                  disabled={
                                    data.ObjectStatusID === ObjectStatus.Closed
                                  }
                                  template={templateType}
                                  action={false}
                                  risk
                                />
                              </Col>
                            </Form.Group>
                          ) : null}
                          {pageStatus === "New" &&
                          formikProps.values.hasTemplate ? ( // && formikProps.values.ParentIDs.length <= 0
                            <Form.Group as={Row}>
                              <Form.Label column sm="2">
                                Use template ?
                              </Form.Label>
                              <Col sm="9">
                                <Form.Check
                                  id="Template"
                                  type="checkbox"
                                  defaultChecked={false}
                                  onChange={(event: any) => {
                                    if (event.target.checked) {
                                      formikProps.setValues({
                                        ...formikProps.values,
                                        RecordStatusID: event.target.id,
                                      });
                                    } else {
                                      formikProps.setValues({
                                        ...formikProps.values,
                                        RecordStatusID: "",
                                      });
                                    }
                                  }}
                                  label="Yes, I want to use a template"
                                />
                              </Col>
                            </Form.Group>
                          ) : null}
                          {pageStatus === "New" &&
                          formikProps.values.hasTemplate &&
                          formikProps.values.RecordStatusID === "Template" &&
                          formikProps.values.TemplateData !== undefined ? ( // && formikProps.values.ParentIDs.length <= 0
                            <Form.Group as={Row}>
                              <Form.Label column sm="2" />
                              <Col sm="9">
                                <Col sm="9">
                                  {TemplateOptions(
                                    formikProps.values.TemplateData,
                                    formikProps.setFieldValue,
                                    formikProps.values.TemplateRequirementID,
                                  )}
                                </Col>
                              </Col>
                            </Form.Group>
                          ) : null}
                          {pageStatus === "New" &&
                          formikProps.values.hasTemplate &&
                          formikProps.values.RecordStatusID === "Template" &&
                          formikProps.values.TemplateData !== undefined &&
                          checkTemplateHasActions(
                            formikProps.values.TemplateData,
                            formikProps.values.TemplateRequirementID!,
                          ) ? ( // && formikProps.values.ParentIDs.length <= 0
                            <>
                              <Form.Group as={Row}>
                                <Form.Label column sm="2">
                                  Template Action Date
                                </Form.Label>
                                <Col sm="2">
                                  <DateField
                                    name="ActionStartTs"
                                    selected={
                                      (formikProps.values.ActionStartTs &&
                                        new Date(
                                          formikProps.values.ActionStartTs,
                                        )) ||
                                      null
                                    }
                                    onUpdate={(val: any) => {
                                      formikProps.setFieldValue(
                                        "ActionStartTs",
                                        val,
                                      );
                                    }}
                                    authState={auth}
                                    allDates={false}
                                    min={moment().toDate()}
                                  />
                                </Col>

                                <Col sm="3">
                                  <Select
                                    onUpdate={(e: any) => {
                                      formikProps.setValues({
                                        ...formikProps.values,
                                        ActionTimezone: e.value,
                                      });
                                    }}
                                    name="ActionTimezone"
                                    value={
                                      formikProps.values.ActionTimezone
                                        ? formikProps.values.ActionTimezone
                                        : ""
                                    }
                                    unselectedOption={
                                      formikProps.values
                                        .ParentObjectTypeGroup !== "Issue" &&
                                      pageStatus === "New"
                                        ? "Select a Value"
                                        : undefined
                                    }
                                    optionsList={timezones}
                                    optionsChild={(listItem: any) => ({
                                      value: listItem,
                                      label: listItem,
                                    })}
                                    readOnly={false}
                                    // readOnlyValue={formikProps.values.ActionTimezone}
                                    disabled={
                                      data.ObjectStatusID ===
                                      ObjectStatus.Closed
                                    }
                                  />
                                </Col>
                              </Form.Group>
                              <Row style={{ marginBottom: "15px" }}>
                                <Col sm={{ offset: 2 }}>
                                  {" "}
                                  <span style={{ color: "#a2aab0" }}>
                                    This date will be used for all actions
                                    created by this template
                                  </span>
                                </Col>
                              </Row>
                            </>
                          ) : null}
                          <Form.Group as={Row}>
                            {pageStatus === "Editing" ||
                            pageStatus === "New" ? (
                              <>
                                <Col
                                  xs="6"
                                  sm="6"
                                  md={{ span: "auto", offset: "2" }}>
                                  <Button
                                    type="submit"
                                    onClick={() => {
                                      const extraErrors = [];
                                      if (
                                        pageStatus === "New" &&
                                        formikProps.values.hasTemplate &&
                                        formikProps.values.RecordStatusID ===
                                          "Template" &&
                                        formikProps.values.TemplateData !==
                                          undefined
                                      ) {
                                        // && formikProps.values.ParentIDs.length <= 0
                                        if (
                                          formikProps.values
                                            .TemplateRequirementID === ""
                                        ) {
                                          extraErrors.push(
                                            '"Template selection is required."',
                                          );
                                        }
                                        if (
                                          checkTemplateHasActions(
                                            formikProps.values.TemplateData!,
                                            formikProps.values
                                              .TemplateRequirementID!,
                                          ) &&
                                          (formikProps.values.ActionStartTs ===
                                            undefined ||
                                            !moment(
                                              formikProps.values.ActionStartTs,
                                            ).isValid())
                                        ) {
                                          extraErrors.push(
                                            '"Template Action Date is required."',
                                          );
                                        }
                                      }
                                      if (
                                        !(
                                          formikProps.values.ParentIDs
                                            .length === 0 && templateType
                                        )
                                      ) {
                                        // Only check Custom Fields if its not a template at top level
                                        formikProps.values.CustomFields.forEach(
                                          (cf: any) => {
                                            if (
                                              cf.IsMandatory &&
                                              (cf.Value === null ||
                                                cf.Value === "" ||
                                                cf.Value.length <= 0)
                                            ) {
                                              extraErrors.push(
                                                `\"\'${cf.Label}\' is a mandatory custom field.\"`,
                                              );
                                            }
                                          },
                                        );
                                      }
                                      let errors = JSON.stringify(
                                        formikProps.errors,
                                      )
                                        .replace("{", "")
                                        .replace("}", "")
                                        .split(",");
                                      errors.map(
                                        (e: string, index: number) =>
                                          (errors[index] = e
                                            .substring(e.lastIndexOf(":") + 1)
                                            .replace("}", "")),
                                      );
                                      errors = errors.concat(extraErrors);
                                      if (
                                        (errors.length > 0 &&
                                          errors[0] !== "") ||
                                        (errors.length > 1 && errors[0] === "")
                                      ) {
                                        const message = `Failed to ${
                                          pageStatus === "New"
                                            ? "create"
                                            : "update"
                                        } ${
                                          formikProps.values.ObjectTypeName
                                        }: \n\n${errors
                                          .filter(
                                            (error: string) =>
                                              !error.includes("null"),
                                          )
                                          .map((error: string) =>
                                            error
                                              .replace("[", "")
                                              .replace("]", ""),
                                          )
                                          .join("\n")}`;
                                        displayToast({
                                          status: "error",
                                          title: message,
                                        });
                                      } else if (
                                        formikProps.values.RequirementName ===
                                          "" &&
                                        formikProps.values
                                          .RequirementResponsibleUserID ===
                                          "" &&
                                        formikProps.isSubmitting === false
                                      ) {
                                        errors.push(
                                          `Name is required.`,
                                          `Responsible user is required.`,
                                        );
                                        const message = `Failed to ${
                                          pageStatus === "New"
                                            ? "create"
                                            : "update"
                                        } ${
                                          formikProps.values.ObjectTypeName
                                        }: \n${errors.join("\n")}`;
                                        displayToast({
                                          status: "error",
                                          title: message,
                                        });
                                      }
                                    }}>
                                    Submit
                                  </Button>
                                </Col>
                                <Col xs="6" sm="6" md="auto">
                                  <Button
                                    type="button"
                                    variant="light"
                                    onClick={() => {
                                      // remove the local storage after cancel
                                      window.localStorage.removeItem(
                                        `${window.location.href}`,
                                      );
                                      setSavedData(null);
                                      if (pageStatus === "Editing") {
                                        formikProps.resetForm();
                                        if (savedData) {
                                          setData(originalData);
                                        }
                                        setPageStatus("Ready");
                                        window.localStorage.removeItem(
                                          `${window.location.href}`,
                                        );
                                      } else {
                                        window.localStorage.removeItem(
                                          `${window.location.href}`,
                                        );
                                        formikProps.values.ParentIDs.length >= 1
                                          ? navigate(
                                              `${
                                                templateType ? "/template" : ""
                                              }/${thisObjectTypeGroupName}/${
                                                formikProps.values.ParentIDs[0]
                                                  .ParentID
                                              }${display}`,
                                            )
                                          : navigate(
                                              `${
                                                templateType ? "/template" : ""
                                              }/${thisObjectTypeGroupName}`,
                                            );
                                      }
                                    }}>
                                    Cancel
                                  </Button>
                                </Col>
                              </>
                            ) : null}
                          </Form.Group>
                        </Form>
                      );
                    }}
                  />
                  {pageStatus !== "New" &&
                  pageStatus !== "Editing" &&
                  pageStatus !== "Submitting" ? (
                    <History
                      ID={data.RequirementID}
                      authState={auth}
                      lastModifiedTs={data.ModifiedTs}
                      lastModififedUser={data.ModifiedUserName}
                    />
                  ) : null}
                </Col>
              </Row>
            </Collapse>
          </section>

          {app.features.enabled.includes("manual") && pageStatus === "Ready" &&
          thisObjectTypeGroupName === "requirement" &&
          !templateType ? (
              <RequirementManualSectionLinks recordID={requirementID} />
          ) : null}

          {pageStatus === "Ready" && !templateType ? (
            <TemplateDocumentGenerator
              data={data}
              authState={auth}
              recordID={data.RequirementID}
            />
          ) : null}

          {(pageStatus === "Ready" || pageStatus === "Editing") &&
          data.ObjectTypeTree &&
          data.ObjectTypeTree.length !== 0
            ? data.ObjectTypeTree.map((obj: any, index: number) => (
                <RelatedCard
                  key={index}
                  object={obj}
                  setData={setData}
                  templateType={templateType}
                  data={data}
                />
              ))
            : null}

          {pageStatus === "Ready" || pageStatus === "Editing" ? (
            <>
              {data.ParentIDs.length <= 0 &&
              thisObjectTypeGroupName !== "job" &&
              displaySubSection(templateType, data.mandatoryParent) &&
              thisObjectTypeGroupName !== "register" ? ( // if it is a template
                <section className="card">
                  <Row>
                    <Col>
                      <h1>
                        {thisObjectTypeGroupName === "risk"
                          ? "Control"
                          : `Sub ${thisObjectTypeGroupName}`}
                        s
                      </h1>
                    </Col>
                    {thisObjectTypeGroupName === "risk" &&
                    data.ObjectTypeName !== "Control" ? (
                      <Col style={{ textAlign: "right" }}>
                        <Dropdown
                          id="dropdown-basic-button"
                          alignRight
                          onSelect={async (eventKey: any) => {
                            const selectedControl: any = data.Controls.find(
                              (control: any) =>
                                control.RequirementID === eventKey,
                            );
                            setData({
                              ...data,
                              ControlChanges: [
                                { ...selectedControl, inserted: true },
                              ],
                              ControlIDs: [...data.ControlIDs, selectedControl],
                            });
                            setPageStatus("Loading");
                            // modify what we pass up for the backend to deal with easier
                            let customFields: any[] = data.CustomFields.map(
                              (field: any) => ({
                                ...field,
                                Value: field.Value === "" ? null : field.Value,
                              }),
                            );
                            customFields = customFields.map((field: any) => {
                              if (
                                field.CustomFieldTypeColumnName ===
                                  "DocumentID" ||
                                field.CustomFieldTypeColumnName ===
                                  "OneDriveDocumentID"
                              ) {
                                if (field.Value !== null) {
                                  field.Value = field.Value.map((doc: any) => {
                                    if (doc.status) {
                                      return { ...doc, buffer: {} };
                                    }
                                    return doc;
                                  });
                                }
                              }
                              return field;
                            });
                            const result = await post(
                              `risk/${requirementID}`,
                              {
                                ...data,
                                CustomFields: customFields,
                                ControlChanges: [
                                  { ...selectedControl, inserted: true },
                                ],
                              },
                              auth,
                            );
                            if (result.status === 200) {
                              displayToast({
                                status: "success",
                                title: `Control added succesfully`,
                              });
                              window.location.reload();
                            }
                          }}>
                          <Dropdown.Toggle
                            variant="primary"
                            id="dropdown-newobjecttype">
                            Add Existing Control
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="newObjectDropdown">
                            <div style={{ padding: "10px" }}>
                              <Text
                                name="ControlSearch"
                                value={data.controlSearch}
                                readOnly={false}
                                onUpdate={(event: any) => {
                                  setData({
                                    ...data,
                                    controlSearch: event.target.value,
                                  });
                                }}
                                placeHolder="Search for a Control"
                              />
                            </div>
                            <Dropdown.Divider />
                            {Access.objectTypeGroupIDToName(
                              data.ObjectTypeGroupID,
                            ) === PermissionCodes.CodeRisk &&
                            templateType === false
                              ? data
                                  .Risks!.filter(
                                    (risk: any) =>
                                      // Only display control objects, that have yet to be added to this risk, and that match the control search term
                                      // OR if template mode add the ones in controls if not already added
                                      risk.Control &&
                                      data.ControlIDs.find(
                                        (control: any) =>
                                          control.RequirementID ===
                                          risk.RequirementID,
                                      ) === undefined &&
                                      risk.RequirementName.toLowerCase().includes(
                                        data.controlSearch!.toLowerCase(),
                                      ),
                                  )
                                  .map((control: any) => (
                                    <Dropdown.Item
                                      key="RequirementID"
                                      eventKey={control.RequirementID}>
                                      <Row>
                                        <Col>
                                          {control.RequirementReadableID.concat(
                                            "-",
                                            control.RequirementName,
                                          )}
                                        </Col>
                                      </Row>
                                    </Dropdown.Item>
                                  ))
                              : templateType
                              ? data
                                  .Controls!.filter(
                                    (risk: any) =>
                                      // Only display control objects, that have yet to be added to this risk, and that match the control search term
                                      // OR if template mode add the ones in controls if not already added
                                      risk.Control &&
                                      data.ControlIDs.find(
                                        (control: any) =>
                                          control.RequirementID ===
                                          risk.RequirementID,
                                      ) === undefined &&
                                      risk.RequirementName.toLowerCase().includes(
                                        data.controlSearch!.toLowerCase(),
                                      ),
                                  )
                                  .map((control: any) => (
                                    <Dropdown.Item
                                      key="RequirementID"
                                      eventKey={control.RequirementID}>
                                      <Row>
                                        <Col>{control.RequirementName} </Col>
                                      </Row>
                                    </Dropdown.Item>
                                  ))
                              : null}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                    ) : null}
                    {thisObjectTypeGroupName === "issue" ? (
                      <Col sm={{ span: "auto" }}>
                        <Button
                          onClick={() => {
                            navigate(
                              `${
                                templateType ? "/template" : ""
                              }/${thisObjectTypeGroupName}/new?type=${
                                data.ObjectTypeID
                              }&parentid=${data.RequirementID}`,
                            );
                          }}>
                          Create New Sub - issue
                        </Button>
                      </Col>
                    ) : (
                      <Col sm={{ span: "auto" }}>
                        <NewObjectTypeButton
                          parentID={data.RequirementID}
                          title={`Create New ${
                            thisObjectTypeGroupName === "risk"
                              ? "Control"
                              : `Sub-${thisObjectTypeGroupName}`
                          }`}
                          header={`New ${
                            thisObjectTypeGroupName === "risk"
                              ? "control"
                              : thisObjectTypeGroupName
                          }`}
                          objectTypeGroupID={thisObjectTypeGroup}
                          template={templateType}
                          type={data.ParentIDs.length === 0 ? 0 : 1}
                          variant="primary"
                          parentType={thisObjectTypeGroup}
                        />
                      </Col>
                    )}
                    <Col sm={{ span: "auto" }}>
                      <CollapseChevron
                        collapsed={isChildrenCollapsed}
                        updateCollapsed={collapseChildren}
                      />
                    </Col>
                  </Row>
                  <Collapse in={!isChildrenCollapsed}>
                    <Row>
                      <Col>
                        <hr />
                        <Table responsive borderless>
                          <thead>
                            <tr>
                              <th />
                              <th> Name </th>
                              <th>
                                {thisObjectTypeGroupName === "risk"
                                  ? "Control"
                                  : toProperCase(thisObjectTypeGroupName)}{" "}
                                Type
                              </th>
                              <th> Status </th>
                              <th> Description </th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.Requirements.filter(
                              (requirement: any) =>
                                requirement.ParentIDs.indexOf(
                                  data.RequirementID,
                                ) >= 0 &&
                                (requirement.Permission || templateType),
                            )
                              .sort((a, b) =>
                                a.RequirementName.localeCompare(
                                  b.RequirementName,
                                  "en",
                                  { numeric: true },
                                ),
                              )
                              .map((requirement: any, index: number) => {
                                const IconType: keyof typeof Feather =
                                  requirement.ObjectTypeIcon;
                                const Icon = Feather[IconType];
                                return (
                                  <tr key={index}>
                                    <td>
                                      <Icon />
                                    </td>
                                    {templateType &&
                                    data.Controls.find(
                                      (c: any) =>
                                        c.RequirementID ===
                                        requirement.RequirementID,
                                    ) !== undefined ? (
                                      requirement.RequirementName.length >
                                      25 ? (
                                        <OverlayTrigger
                                          placement="left"
                                          overlay={
                                            <Tooltip
                                              id={requirement.RequirementName}>
                                              <Textarea
                                                value={
                                                  requirement.RequirementName
                                                }
                                                name={`SubRequirementDescription${index}`}
                                                readOnly
                                                richText
                                              />
                                            </Tooltip>
                                          }>
                                          <td
                                            style={{
                                              whiteSpace: "nowrap",
                                            }}>
                                            <Textarea
                                              value={text_truncate(
                                                requirement.RequirementName,
                                                25,
                                              )}
                                              name={`SubRequirementName${index}`}
                                              readOnly
                                              richText
                                            />
                                          </td>
                                        </OverlayTrigger>
                                      ) : (
                                        <td>
                                          <Textarea
                                            value={requirement.RequirementName}
                                            name={`SubRequirementName${index}`}
                                            readOnly
                                            richText
                                          />
                                        </td>
                                      )
                                    ) : requirement.RequirementName.length >
                                      25 ? (
                                      <td style={{ whiteSpace: "nowrap" }}>
                                        <OverlayTrigger
                                          placement="right-end"
                                          overlay={
                                            <Tooltip
                                              id={requirement.RequirementName}>
                                              {
                                                requirement.RequirementReadableID
                                              }{" "}
                                              - {requirement.RequirementName}
                                            </Tooltip>
                                          }>
                                          <Link
                                            to={
                                              templateType
                                                ? `/template/${thisObjectTypeGroupName}/${
                                                    requirement.RequirementID
                                                  }${
                                                    display
                                                      ? `${display}&`
                                                      : "?"
                                                  }parentid=${
                                                    data.RequirementID
                                                  }`
                                                : `/${thisObjectTypeGroupName}/${
                                                    requirement.RequirementID
                                                  }${
                                                    display
                                                      ? `${display}&`
                                                      : "?"
                                                  }parentid=${
                                                    data.RequirementID
                                                  }`
                                            }>
                                            {requirement.RequirementReadableID}{" "}
                                            -{" "}
                                            {text_truncate(
                                              requirement.RequirementName,
                                              25,
                                            )}
                                          </Link>
                                        </OverlayTrigger>
                                      </td>
                                    ) : (
                                      <td>
                                        <Link
                                          to={
                                            templateType
                                              ? `/template/${thisObjectTypeGroupName}/${
                                                  requirement.RequirementID
                                                }${
                                                  display ? `${display}&` : "?"
                                                }parentid=${data.RequirementID}`
                                              : `/${thisObjectTypeGroupName}/${
                                                  requirement.RequirementID
                                                }${
                                                  display ? `${display}&` : "?"
                                                }parentid=${data.RequirementID}`
                                          }>
                                          {requirement.RequirementReadableID} -{" "}
                                          {requirement.RequirementName}
                                        </Link>
                                      </td>
                                    )}
                                    <td>{requirement.ObjectTypeName} </td>
                                    <td>
                                      <Status
                                        placement="left"
                                        variant={objectStatusToVariant(
                                          requirement.ObjectStatusID,
                                        )}
                                        text={requirement.ObjectStatusName}
                                        tooltip={
                                          requirement.ObjectStatusDescription
                                        }
                                      />
                                    </td>
                                    {requirement.RequirementDescription.length >
                                    25 ? (
                                      <OverlayTrigger
                                        placement="left"
                                        overlay={
                                          <Tooltip
                                            id={
                                              requirement.RequirementDescription
                                            }>
                                            <Textarea
                                              value={
                                                requirement.RequirementDescription
                                              }
                                              name={`SubRequirementDescription${index}`}
                                              readOnly
                                              richText
                                            />
                                          </Tooltip>
                                        }>
                                        <td
                                          style={{
                                            whiteSpace: "nowrap",
                                          }}>
                                          <Textarea
                                            value={text_truncate(
                                              text_stripHTML(
                                                requirement.RequirementDescription,
                                              ),
                                              25,
                                            )}
                                            name={`SubRequirementDescription${index}`}
                                            readOnly
                                            richText
                                          />
                                        </td>
                                      </OverlayTrigger>
                                    ) : (
                                      <td>
                                        <Textarea
                                          value={
                                            requirement.RequirementDescription
                                          }
                                          name={`SubRequirementDescription${index}`}
                                          readOnly
                                          richText
                                        />
                                      </td>
                                    )}
                                  </tr>
                                );
                              })}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </Collapse>
                </section>
              ) : null}
              <section className="card">
                <Row>
                  <Col>
                    <h1>Actions </h1>
                  </Col>

                  <Col sm={{ span: "auto" }}>
                    <NewObjectTypeButton
                      title="New Action"
                      header="New Action"
                      parentID={requirementID}
                      objectTypeGroupID={ObjectTypeGroup.Action}
                      template={templateType}
                      type={data.ParentIDs.length === 0 ? 1 : 0}
                      parentType={thisObjectTypeGroup}
                      variant="primary"
                    />
                  </Col>
                  <Col sm="auto">
                    <CollapseChevron
                      collapsed={isActionsCollapsed}
                      updateCollapsed={collapseActions}
                    />
                  </Col>
                </Row>
                <Collapse in={!isActionsCollapsed}>
                  <Row>
                    <Col>
                      <hr />
                      <Table responsive borderless>
                        <thead>
                          <tr>
                            <th />
                            <th> Name </th>
                            <th> Responsible User </th>
                            <th> Status </th>
                            <th> Description </th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.Actions.sort((a, b) =>
                            a.ActionReadableID.localeCompare(
                              b.ActionReadableID,
                              "en",
                              {
                                numeric: true,
                              },
                            ),
                          ).map((action: any, index: number) => {
                            const IconType: keyof typeof Feather =
                              action.ObjectTypeIcon;
                            const Icon = Feather[IconType];
                            return (
                              <tr key={index}>
                                <td>
                                  <Icon />
                                </td>
                                {action.ActionName.length > 25 ? (
                                  <OverlayTrigger
                                    placement="right"
                                    overlay={
                                      <Tooltip id={action.ActionName}>
                                        {action.ActionReadableID} -{" "}
                                        {action.ActionName}
                                      </Tooltip>
                                    }>
                                    <td style={{ whiteSpace: "nowrap" }}>
                                      <Link
                                        to={
                                          templateType
                                            ? `/template/action/${
                                                action.ActionID
                                              }${
                                                display ? `${display}&` : "?"
                                              }parentid=${data.RequirementID}`
                                            : `/action/${action.ActionID}${
                                                display ? `${display}&` : "?"
                                              }parentid=${data.RequirementID}`
                                        }>
                                        {action.ActionReadableID} -{" "}
                                        {text_truncate(action.ActionName, 25)}
                                      </Link>
                                    </td>
                                  </OverlayTrigger>
                                ) : (
                                  <td>
                                    <Link
                                      to={
                                        templateType
                                          ? `/template/action/${
                                              action.ActionID
                                            }${
                                              display ? `${display}&` : "?"
                                            }parentid=${data.RequirementID}`
                                          : `/action/${action.ActionID}${
                                              display ? `${display}&` : "?"
                                            }parentid=${data.RequirementID}`
                                      }>
                                      {action.ActionReadableID} -{" "}
                                      {action.ActionName}
                                    </Link>
                                  </td>
                                )}
                                <td>{action.ActionResponsibleUserName} </td>
                                <td>
                                  <Status
                                    placement="left"
                                    variant={objectStatusToVariant(
                                      action.ObjectStatusID,
                                    )}
                                    text={action.ObjectStatusName}
                                    tooltip={action.ObjectStatusDescription}
                                  />
                                </td>
                                {action.ActionDescription.length > 25 ? (
                                  <OverlayTrigger
                                    placement="left"
                                    overlay={
                                      <Tooltip id={action.ActionDescription}>
                                        <Textarea
                                          value={action.ActionDescription}
                                          name={`ActionDescription${index}`}
                                          readOnly
                                          richText
                                        />
                                      </Tooltip>
                                    }>
                                    <td style={{ whiteSpace: "nowrap" }}>
                                      <Textarea
                                        value={text_truncate(
                                          text_stripHTML(
                                            action.ActionDescription,
                                          ),
                                          25,
                                        )}
                                        name={`ActionDescription${index}`}
                                        readOnly
                                        richText
                                      />
                                    </td>
                                  </OverlayTrigger>
                                ) : (
                                  <td>
                                    <Textarea
                                      value={action.ActionDescription}
                                      name={`ActionDescription${index}`}
                                      readOnly
                                      richText
                                    />
                                  </td>
                                )}
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Collapse>
              </section>
              {thisObjectTypeGroup === ObjectTypeGroup.Register &&
              !templateType ? (
                <section className="card">
                  <Row>
                    <Col>
                      <OverlayTrigger
                        placement="auto"
                        overlay={
                          <Tooltip id="start-an-action-info">
                            Add an Action here and it will appear in the 'Start
                            an Action' menu for this {data.ObjectTypeName}
                          </Tooltip>
                        }>
                        <h1>On Demand Actions </h1>
                      </OverlayTrigger>
                    </Col>
                    <Col />

                    <Col sm={{ span: "auto" }}>
                      {Access.checkAccess(
                        app.permissions_LEGACY,
                        Access.objectTypeGroupIDToName(
                          getObjectTypeGroup(thisObjectTypeGroupName),
                        ),
                        Permissions.TypeUpdate,
                        app.attributes.userID ===
                          data.RequirementResponsibleUserID,
                        data.ObjectTypeID,
                      ) ? (
                        <Dropdown
                          id="dropdown-basic-button"
                          alignRight
                          onSelect={async (actionID: string) => {
                            try {
                              const req = await put(
                                `app/ondemandaction`,
                                {
                                  ActionID: actionID,
                                  RequirementID: data.RequirementID,
                                },
                                auth,
                              );
                              setPageStatus("UpdatingOnDemandActions");
                              if (req.status === 200 && req.data) {
                                displayToast({
                                  status: "success",
                                  title: `On Demand Action added succesfully`,
                                });
                                setData({
                                  ...data,
                                  OnDemandActions: [
                                    ...data.OnDemandActions,
                                    ...req.data.OnDemandActions,
                                  ],
                                });
                                setPageStatus("Ready");
                              } else {
                                displayToast({
                                  status: "error",
                                  title: `Failed to add new On Demand Action`,
                                });
                                setPageStatus("Ready");
                              }
                            } catch (e) {
                              displayToast({
                                status: "error",
                                title: `Failed to add new On Demand Action`,
                              });
                              setPageStatus("Ready");
                            }
                          }}>
                          <Dropdown.Toggle
                            variant="primary"
                            id="dropdown-newobjecttype">
                            Add On Demand Action
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="newObjectDropdown">
                            <div style={{ padding: "10px" }}>
                              <Text
                                name="Action Search"
                                value={onDemandActionSearch}
                                readOnly={false}
                                onUpdate={(event: any) => {
                                  event.preventDefault();
                                  setOnDemandActionSearch(event.target.value);
                                }}
                                placeHolder="Search for Action Template"
                              />
                            </div>
                            <Dropdown.Divider />
                            {data.TemplateActions.filter(
                              (action: any) =>
                                !data.OnDemandActions.some(
                                  (act) => act.ActionID === action.ActionID,
                                ) &&
                                `${action.RequirementName} - ${action.ActionName}`
                                  .toLowerCase()
                                  .includes(onDemandActionSearch.toLowerCase()),
                            ).map((action: any) => (
                              <Dropdown.Item
                                key={action.ActionID}
                                eventKey={action.ActionID}>
                                <Row>
                                  <Col>
                                    {`${action.RequirementName} - ${action.ActionName}`}
                                  </Col>
                                </Row>
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      ) : (
                        <OverlayTrigger
                          placement="auto"
                          overlay={
                            <Tooltip id="tooltip-not-authorised-to-edit">
                              You do not have permission to add this
                            </Tooltip>
                          }>
                          <Dropdown id="dropdown-basic-button" alignRight>
                            <Dropdown.Toggle
                              disabled
                              variant="primary"
                              id="dropdown-newobjecttype">
                              Add On Demand Action
                            </Dropdown.Toggle>
                          </Dropdown>
                        </OverlayTrigger>
                      )}
                    </Col>
                    <Col sm="auto">
                      <CollapseChevron
                        collapsed={isOnDemandActionsCollapsed}
                        updateCollapsed={collapseOnDemandActions}
                      />
                    </Col>
                  </Row>
                  <Collapse in={!isOnDemandActionsCollapsed}>
                    <Row>
                      <Col>
                        <hr />
                        <Table responsive borderless>
                          <thead>
                            <tr>
                              <th />
                              <th> Name </th>
                              <th> Responsible User </th>
                              <th> Actions </th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.OnDemandActions.sort((a, b) =>
                              a.ActionName.localeCompare(b.ActionName, "en", {
                                numeric: true,
                              }),
                            ).map((action: any, index: number) => {
                              const IconType: keyof typeof Feather =
                                action.ActionObjectTypeIcon;
                              const Icon = Feather[IconType];
                              return (
                                <tr key={index}>
                                  <td style={{ verticalAlign: "middle" }}>
                                    <Icon />
                                  </td>
                                  {action.ActionName.length > 25 ? (
                                    <OverlayTrigger
                                      placement="right"
                                      overlay={
                                        <Tooltip id={action.ActionName}>
                                          {action.ActionReadableID} -{" "}
                                          {action.ActionName}
                                        </Tooltip>
                                      }>
                                      <td
                                        style={{
                                          whiteSpace: "nowrap",
                                          verticalAlign: "middle",
                                        }}>
                                        {action.ActionReadableID} -{" "}
                                        {text_truncate(action.ActionName, 25)}
                                      </td>
                                    </OverlayTrigger>
                                  ) : (
                                    <td style={{ verticalAlign: "middle" }}>
                                      {action.ActionReadableID} -{" "}
                                      {action.ActionName}
                                    </td>
                                  )}
                                  <td style={{ verticalAlign: "middle" }}>
                                    {action.ActionResponsibleUserName}{" "}
                                  </td>

                                  <td>
                                    <HStack
                                      w="full"
                                      spacing="2"
                                      justifyContent="start">
                                      <Button
                                        onClick={confirmationModalState.onOpen}>
                                        Start Task
                                      </Button>
                                      <chakra-scope>
                                        <Modal
                                          isOpen={confirmationModalState.isOpen}
                                          onClose={
                                            confirmationModalState.onClose
                                          }>
                                          <ConfirmationModal
                                            modalState={confirmationModalState}
                                            link={`/ondemandaction/${action.RequirementOnDemandActionID}`}
                                            actionName={`${action.ActionReadableID} - ${action.ActionName}`}
                                          />
                                        </Modal>
                                      </chakra-scope>
                                      {/* in case copying to clipboard doesn't work, user can still right click and copy link */}
                                      <ChakraTooltip label="Copy URL">
                                        <ChakraLink
                                          to={`/ondemandaction/${action.RequirementOnDemandActionID}`}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            try {
                                              navigator.clipboard.writeText(
                                                `${
                                                  window.location.href.split(
                                                    "/",
                                                  )[2]
                                                }/ondemandaction/${
                                                  action.RequirementOnDemandActionID
                                                }`,
                                              );
                                              displayToast({
                                                status: "success",
                                                title: `URL copied to clipboard`,
                                              });
                                            } catch (e) {
                                              displayToast({
                                                status: "error",
                                                title: `Could not copy URL`,
                                              });
                                            }
                                          }}>
                                          <CopyToClipboardIcon
                                            color="#d8d8d8"
                                            size="24"
                                          />
                                        </ChakraLink>
                                      </ChakraTooltip>

                                      {Access.checkAccess(
                                        app.permissions_LEGACY,
                                        Access.objectTypeGroupIDToName(
                                          getObjectTypeGroup(
                                            thisObjectTypeGroupName,
                                          ),
                                        ),
                                        Permissions.TypeUpdate,
                                        app.attributes.userID ===
                                          data.RequirementResponsibleUserID,
                                        data.ObjectTypeID,
                                      ) ? (
                                        <ChakraTooltip
                                          label={`Delete ${action.ActionReadableID} ${action.ActionName}`}>
                                          <button
                                            style={{
                                              backgroundColor: "white",
                                              border: "none",
                                            }}
                                            onClick={async () => {
                                              try {
                                                const req = await del(
                                                  `app/ondemandaction/${action.RequirementOnDemandActionID}`,
                                                  auth,
                                                );
                                                setPageStatus(
                                                  "UpdatingOnDemandActions",
                                                );
                                                if (req.status === 200) {
                                                  displayToast({
                                                    status: "success",
                                                    title: `On Demand Action removed succesfully`,
                                                  });
                                                  setData({
                                                    ...data,
                                                    OnDemandActions:
                                                      data.OnDemandActions.filter(
                                                        (act) =>
                                                          act.RequirementOnDemandActionID !==
                                                          action.RequirementOnDemandActionID,
                                                      ),
                                                  });
                                                  setPageStatus("Ready");
                                                } else {
                                                  displayToast({
                                                    status: "error",
                                                    title: `Failed to remove On Demand Action`,
                                                  });
                                                  setPageStatus("Ready");
                                                }
                                              } catch (e) {
                                                displayToast({
                                                  status: "error",
                                                  title: `Failed to remove On Demand Action`,
                                                });
                                                setPageStatus("Ready");
                                              }
                                            }}>
                                            <Trash2
                                              style={{ color: "#d8d8d8" }}
                                              className="feather"
                                            />
                                          </button>
                                        </ChakraTooltip>
                                      ) : (
                                        <OverlayTrigger
                                          placement="auto"
                                          overlay={
                                            <Tooltip id="tooltip-not-authorised-to-delete">
                                              You do not have permission to
                                              delete this
                                            </Tooltip>
                                          }>
                                          <button
                                            style={{
                                              backgroundColor: "white",
                                              border: "none",
                                            }}
                                            disabled>
                                            <Trash2
                                              style={{ color: "#d8d8d8" }}
                                              className="feather"
                                            />
                                          </button>
                                        </OverlayTrigger>
                                      )}
                                    </HStack>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </Collapse>
                </section>
              ) : null}
            </>
          ) : null}
          {!templateType
            ? pageStatus === "Ready" && (
                <Notes
                  isVisible={notes.isVisible}
                  noteType={notes.noteType}
                  record={{ type: "Requirement", id: data.RequirementID }}
                  handleClose={() =>
                    setNotes({ isVisible: false, noteType: "" })
                  }
                  handleUpdate={(updatedNotes: Note[]) =>
                    setData({ ...data, Notes: updatedNotes })
                  }
                  data={data.Notes.filter(
                    (note: Note) => note.NoteTypeID === notes.noteType,
                  )}
                  taggables={data.ResponsibleUsers.map((user: any) => ({
                    id: `user/${user.UserID}`,
                    display: `${user.UserFirstName} ${user.UserLastName}`,
                  })).concat(
                    data.Roles.map((role: any) => ({
                      id: `role/${role.RoleID}`,
                      display: `${role.RoleName}`,
                    })),
                  )}
                />
              )
            : null}
        </>
      ) : pageStatus !== "Error" ? (
        <div className="progress-spinner" style={{ marginTop: "20%" }}>
          <Loading size="xl" />
        </div>
      ) : (
        <Card2 style={{ marginTop: "20%", textAlign: "center" }}>
          <Row style={{ marginBottom: "20px" }}>
            <Col>
              <Frown />
            </Col>
          </Row>
          <Row>
            <Col>
              <h1>{errorMessage} </h1>
            </Col>
          </Row>
        </Card2>
      )}
    </LegacyScreenContainer>
  );
};

export { ScreensRequirement };
