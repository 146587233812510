import { Button, Col, Row } from "react-bootstrap";
import { Text, Link } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom"
import { useApiQuery } from "../../utilities/apibelRequest";
import { Card } from "../../components/UI/Card/Card";
import { ManualSectionDropdown } from "./ManualSectionDropdown";

type Props = {
  recordID: string;
};

export function RequirementManualSectionLinks({ recordID }: Props) {
  const linkedItemsQuery = useApiQuery("manual/manySectionsForLinkedRecord", {
    recordID,
  });

  return (
    <Card
      collapsable
      title="Manual"
      headerColsRight
      headerCols={[
        {
          colProps: { style: { textAlign: "right" } },
          children: <ManualSectionDropdown selectedIDs={[]} recordID={recordID}/>,
        },
      ]}>
      <chakra-scope>
        {linkedItemsQuery.isSuccess &&
          (linkedItemsQuery.data.sections.length <= 0 ? (
            <Text>No linked sections...</Text>
          ) : (
            linkedItemsQuery.data.sections.map((section) => (
              <Link as={RouterLink} to={`/manual/${section.manual.manualID}`} key={section.manualSectionID}>{section.title}</Link>
            ))
          ))}
      </chakra-scope>
    </Card>
  );
}
