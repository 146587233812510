import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  HStack,
  IconButton,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { RiAddLine, RiPencilLine } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../../components/chakra/Breadcrumbs";
import { ManualSectionData } from "../../../shared/v2/definitions/manuals";
import { useApiMutation, useApiQuery } from "../../../utilities/apibelRequest";
import AddSectionModal from "./components/AddSectionModal";
import ManualSection from "./components/ManualSection";
import TableOfContents from "./components/TableOfContents";
import FullLoader from "../../../components/chakra/FullLoader";
import { ConfirmDeleteModal } from "./components/ConfirmDeleteModal";
import EditManualNameModal from "./components/EditManualNameModal";

function findActiveHeading(rects: DOMRect[]) {
  if (rects.length === 0) {
    return -1;
  }

  const offset = 48;

  const closest = rects.reduce(
    (acc, item, index) => {
      if (Math.abs(acc.position) < Math.abs(item.y) - offset) {
        return acc;
      }

      return {
        index,
        position: item.y,
      };
    },
    { index: 0, position: (rects[0]?.y || 0) - offset },
  );

  return closest.index;
}

const getSectionHTMLID = (section: ManualSectionData) =>
  `${encodeURIComponent(
    section.title.slice(0, 24),
  )}-${section.manualSectionID.slice(0, 8)}`;

export default function ManualScreen() {
  const navigate = useNavigate();
  const params = useParams();
  const { manualID } = params;

  const manualQuery = useApiQuery(
    "manual/byID",
    {
      manualID: manualID || "",
    },
    { enabled: !!manualID },
  );

  const sectionsQuery = useApiQuery(
    "manual/manySections",
    {
      manualID: manualID || "",
      contentType: "full",
    },
    { enabled: !!manualID },
  );

  const createSectionMutation = useApiMutation("manual/createSection");
  const editManualNameMutation = useApiMutation("manual/update");

  const isLoading = manualQuery.isLoading || sectionsQuery.isLoading;
  const isFetching = manualQuery.isFetching || sectionsQuery.isFetching;
  const isMutating =
    createSectionMutation.isLoading || editManualNameMutation.isLoading;

  const [activeIndex, setActiveIndex] = useState(0);
  const sectionModal = useDisclosure({ defaultIsOpen: false });
  const deleteModal = useDisclosure({ defaultIsOpen: false });
  const [deleteSection, setDeleteSection] = useState<{
    name: string;
    sectionID: string;
  } | null>(null);
  const editNameModal = useDisclosure({ defaultIsOpen: false });
  const [editName, setEditName] = useState("");

  const manualSectionRefs = useRef<(HTMLHeadingElement | null)[]>([]);

  const handleScroll = useCallback(() => {
    const headingRects = manualSectionRefs.current.map((headingEl) => {
      if (headingEl) {
        return headingEl.getBoundingClientRect();
      }
      return new DOMRect();
    });
    const activeHeading = findActiveHeading(headingRects);
    setActiveIndex(activeHeading);
  }, []);

  useEffect(() => {
    setActiveIndex(0);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const handleCloseAddSection = () => {
    sectionModal.onClose();
  };

  const handleConfirmAddSection = async (title: string) => {
    sectionModal.onClose();
    if (manualID && manualQuery.isSuccess) {
      await createSectionMutation.mutateAsync({
        section: {
          manualID,
          title,
        },
      });
      sectionsQuery.refetch();
    }
  };

  const handleOpenAddSection = () => {
    if (isMutating) return;
    sectionModal.onOpen();
  };

  const handleCloseDeleteSection = () => {
    deleteModal.onClose();
    setDeleteSection(null);
  };

  const handleConfirmDeleteSection = () => {
    if (deleteSection) {
      deleteModal.onClose();
      setDeleteSection(null);
      // delete section
    }
  };

  const handleOpenDeleteSection = (sectionID: string, sectionName: string) => {
    setDeleteSection({ sectionID, name: sectionName });
    deleteModal.onOpen();
  };

  const handleClickContents = (index: number) => {
    const el = manualSectionRefs.current[index];
    if (el) {
      el.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleEdit = (sectionID: string) => {
    navigate(`/manual/section/${sectionID}/edit`);
  };

  const handleClickEditName = () => {
    setEditName(manualQuery.data?.name || "");
    editNameModal.onOpen();
  };

  const handleCancelEditName = () => {
    editNameModal.onClose();
  };

  const handleConfirmEditName = async () => {
    editNameModal.onClose();
    if (manualID) {
      await editManualNameMutation.mutateAsync({
        manual: {
          manualID,
          name: editName,
        },
      });
      manualQuery.refetch();
    }
  };

  const sortedSections =
    sectionsQuery.data?.sections.sort((a, b) => {
      return a.order - b.order;
    }) || [];

  const contents =
    sortedSections.map((section) => ({
      label: section.title,
      link: `#${getSectionHTMLID(section)}`,
    })) || [];

  const sections = sortedSections.map((section, idx) => {
    return (
      <Flex
        w="100%"
        justify="center"
        _last={{ minH: "calc(30vh - var(--chakra-space-top-bar))" }}>
        <ManualSection
          key={section.manualSectionID}
          onClickDelete={() =>
            handleOpenDeleteSection(section.manualSectionID, section.title)
          }
          sectionHTMLID={getSectionHTMLID(section)}
          title={section.title}
          contentJSON={section.content.jsonStr || ""}
          modified={{
            firstName: section.modifiedUser.firstName,
            lastName: section.modifiedUser.lastName,
            userID: section.modifiedUser.userID,
            date: section.modifiedTs,
          }}
          responsibleUser={section.responsibleUser}
          onClickEdit={() => handleEdit(section.manualSectionID)}
          ref={(ref) => {
            manualSectionRefs.current[idx] = ref;
          }}
        />
      </Flex>
    );
  });

  return (
    <chakra-scope>
      <Box minH="100vh" w="100%">
        <Box
          pos="fixed"
          top="top-bar"
          w="100%"
          h="48px"
          zIndex={100}
          pt="2"
          pl="4"
          pr="4"
          bg="white">
          <VStack w="full" align="start" spacing="0">
            <HStack justify="space-between" w="full">
              <HStack
                align="center"
                spacing={2}
                _hover={{
                  "& .edit-button": { visibility: "visible", opacity: 1 },
                }}>
                <HStack spacing={0}>
                  <Breadcrumbs
                    breadcrumbs={[{ link: "/manual", label: "Manuals" }]}
                  />
                  <Heading
                    as="h1"
                    size="md"
                    color={
                      manualQuery.isFetching ||
                      editManualNameMutation.isLoading
                        ? "gray.500"
                        : "gray.700"
                    }>
                    {manualQuery.data?.name || "Manual"}
                  </Heading>
                </HStack>
                <Tooltip label="Edit manual name">
                  <IconButton
                    visibility="hidden"
                    opacity={0}
                    isDisabled={isMutating || isFetching}
                    className="edit-button"
                    size="sm"
                    onClick={handleClickEditName}
                    aria-label="Edit manual name"
                    variant="ghost">
                    <RiPencilLine size="16" />
                  </IconButton>
                </Tooltip>
              </HStack>
              {!isLoading && (
                <ButtonGroup size="sm">
                  {/* <Button isDisabled={isMutating || isFetching}>
                    Export Manual
                  </Button> */}
                  <Button
                    isDisabled={isMutating || isFetching}
                    onClick={handleOpenAddSection}>
                    Add Section
                  </Button>
                </ButtonGroup>
              )}
            </HStack>
          </VStack>
        </Box>
        {isLoading ? (
          <Stack
            mt="24"
            w="calc(100vw-332px)"
            flex="2"
            align="center"
            justify="center">
            <FullLoader />
          </Stack>
        ) : (
          <Stack
            mt="52px"
            w="calc(100vw - 332px)"
            flex="2"
            align="start"
            justify="center"
            spacing="16">
            {sections}
          </Stack>
        )}
        <Flex align="center" justify="center" w="calc(100vw - 332px)">
          {!isFetching && (
            <Flex
              justify="center"
              pt="16"
              minH="70vh"
              maxW="container.lg"
              w="100%">
              <Stack
                role="button"
                onClick={handleOpenAddSection}
                borderRadius="sm"
                cursor="pointer"
                align="center"
                justify="center"
                maxH="320px"
                w="100%"
                borderStyle="dashed"
                borderWidth="2px"
                _hover={{
                  borderColor: "brand.200",
                }}>
                <HStack>
                  <RiAddLine size="24" />
                  <Text fontSize="xl">Add new section</Text>
                </HStack>
              </Stack>
            </Flex>
          )}
        </Flex>
        <Box
          pos="fixed"
          top="calc(var(--chakra-space-top-bar) + 48px + 1rem)"
          right="2"
          pl="2"
          w="320px">
          <TableOfContents
            items={contents}
            activeIndex={activeIndex}
            onClick={handleClickContents}
          />
        </Box>
      </Box>
      <AddSectionModal
        isOpen={sectionModal.isOpen}
        onClose={handleCloseAddSection}
        onConfirm={handleConfirmAddSection}
      />
      <EditManualNameModal
        title={editName}
        isOpen={editNameModal.isOpen}
        onClose={handleCancelEditName}
        onConfirm={handleConfirmEditName}
        onChangeTitle={(value) => setEditName(value)}
      />
      <ConfirmDeleteModal
        sectionName={deleteSection?.name || ""}
        isOpen={deleteModal.isOpen}
        onClose={handleCloseDeleteSection}
        onDelete={handleConfirmDeleteSection}
      />
    </chakra-scope>
  );
}
