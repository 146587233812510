import {
  Badge,
  Button,
  ButtonGroup,
  chakra,
  Flex,
  HStack,
  Icon,
  LinkBox,
  LinkOverlay,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
  FormLabel,
  Box,
} from "@chakra-ui/react";

import { useEffect, useState } from "react";
import { RiInformationLine } from "react-icons/ri";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import ChakraScreenContainer from "../../components/chakra/ChakraScreenContainer";
import Spinner from "../../components/chakra/common/Spinner";
import DebouncedInput from "../../components/DebouncedInput";
import { UserServicePermissions } from "../../shared/v2/constants/ServicePermissions";
import { useApiQuery } from "../../utilities/apibelRequest";
import NotFoundScreen from "../NotFound";

type Props = {
  servicePermissions: UserServicePermissions;
};

export default function ServiceManageUsersScreen({
  servicePermissions,
}: Props) {
  const emptyColor = useColorModeValue("blackAlpha.500", "whiteAlpha.500");

  const [page, setPage] = useState(1);
  const [email, setEmail] = useState<string | undefined>();
  const [name, setName] = useState<string | undefined>();

  const [searchParams, setSearchParams] = useSearchParams();

  const usersQuery = useApiQuery("service/allUsers", { page, email, name });

  const emailSearchHandler = (value?: string) => {
    setSearchParams((params) => {
      params.set("page", "1");
      if (value) {
        if (params.has("email")) {
          params.set("email", value);
        } else {
          params.append("email", value);
        }
      } else {
        params.delete("email");
      }
      return params;
    });
    setEmail(value);
  };

  const nameSearchHandler = (value?: string) => {
    setSearchParams((params) => {
      params.set("page", "1");
      if (value) {
        if (params.has("name")) {
          params.set("name", value);
        } else {
          params.append("name", value);
        }
      } else {
        params.delete("name");
      }
      return params;
    });
    setName(value);
  };

  useEffect(() => {
    const pageParam = searchParams.get("page");
    if (pageParam) {
      setPage(parseInt(pageParam, 10));
    }
    const emailParam = searchParams.get("email");
    if (emailParam && emailParam !== email && emailParam !== "undefined") {
      setEmail(emailParam);
    }
    const nameParam = searchParams.get("name");
    if (nameParam && nameParam !== name && nameParam !== "undefined") {
      setName(nameParam);
    }
  }, [searchParams]);

  if (!servicePermissions["manage-users"]) {
    return <NotFoundScreen />;
  }

  const { isFetching, isLoading, isSuccess } = usersQuery;

  const getPageItems = () => {
    return (
      <>
        <Button
          onClick={() => {
            setPage(usersQuery.data?.previous || 1);
            setSearchParams({
              page: usersQuery.data?.previous?.toString() || "1",
            });
          }}
          disabled={!usersQuery.data?.previous}>
          Prev
        </Button>
        <Button
          onClick={() => {
            setPage(usersQuery.data?.next || 1);
            setSearchParams({
              page: usersQuery.data?.next?.toString() || "1",
            });
          }}
          disabled={!usersQuery.data?.next}>
          Next
        </Button>
      </>
    );
  };

  const getSearchItems = () => {
    return (
      <Flex gap={4}>
        <div>
          <FormLabel htmlFor="name">Name</FormLabel>
          <DebouncedInput
            onChange={(value) => {
              nameSearchHandler(value);
            }}
            value={name || undefined}
            placeholder="Search by users name"
          />
        </div>
        <div>
          <FormLabel htmlFor="email">Email</FormLabel>
          <DebouncedInput
            onChange={(value) => {
              emailSearchHandler(value);
            }}
            value={email || undefined}
            placeholder="Search by email"
          />
        </div>
      </Flex>
    );
  };

  return (
    <ChakraScreenContainer
      pageTitle="Manage Users"
      headerBottomContent={getSearchItems()}
      breadcrumbs={[{ label: "Service Portal", link: "/_ccserviceportal" }]}>
      <TableContainer w="full" pt={2} pb={4}>
        <Box position="relative">
          <Table
            isFetching={isFetching}
            variant="data-table"
            minH={100}
            size="sm"
            position="relative">
            <Thead position="relative" maxH="fit-content">
              <Tr>
                <Th>User </Th>
                <Th> Primary Email </Th>
                <Th> Secondary Email </Th>
                <Th> Is Internal </Th>
                <Th> Accounts </Th>
              </Tr>
            </Thead>
            {isLoading && (
              <Tbody>
                <Tr>
                  <Td colSpan={5}>
                    <Flex justifyContent="center" alignItems="center" h="320px">
                      <Spinner />
                    </Flex>
                  </Td>
                </Tr>
              </Tbody>
            )}

            {isSuccess && (
              <>
                <Tbody>
                  {isFetching && (
                    <Spinner
                      zIndex={1}
                      opacity={1}
                      size="lg"
                      position="absolute"
                      bottom="50%"
                      left="50%"
                    />
                  )}

                  {usersQuery.data?.users.map((user) => (
                    <Tr key={user.userID} maxH={"fit-content"}>
                      <LinkBox
                        _hover={{ textDecor: "underline" }}
                        as={Td}
                        maxW={250}
                        overflowX="clip">
                        <LinkOverlay
                          ps="2"
                          as={RouterLink}
                          display="flex"
                          flexDir="column"
                          alignItems="start"
                          to={`/_ccserviceportal/user/${user.userID}`}>
                          <Badge colorScheme="gray"> {user.userID} </Badge>
                          <chakra.span fontWeight="bold">
                            {user.firstName} {user.lastName}
                          </chakra.span>
                        </LinkOverlay>
                      </LinkBox>
                      <Td> {user.email} </Td>
                      <Td
                        color={!user.secondaryEmail ? emptyColor : undefined}
                        fontStyle={!user.secondaryEmail ? "italic" : undefined}>
                        {user.secondaryEmail || "None"}
                      </Td>
                      <Td> {user.isInternal ? "True" : "False"} </Td>
                      <Td>
                        <Tooltip
                          label={user.userAccounts
                            .map((ua) => ua.accountName)
                            .join(", ")}>
                          <HStack as="span" align="center">
                            <span>{user.userAccounts.length} Accounts </span>
                            <Icon
                              boxSize={4}
                              color="blue.300"
                              as={RiInformationLine}
                            />
                          </HStack>
                        </Tooltip>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>

                <Tfoot>
                  <Tr>
                    <Th>User </Th>
                    <Th> Primary Email </Th>
                    <Th> Secondary Email </Th>
                    <Th> Is Internal </Th>
                    <Th> Accounts </Th>
                  </Tr>
                </Tfoot>
              </>
            )}
          </Table>
          <Spacer />
          <ButtonGroup size="sm" variant="outline" isAttached>
            {getPageItems()}
          </ButtonGroup>
        </Box>
      </TableContainer>
    </ChakraScreenContainer>
  );
}
