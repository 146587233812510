import { Formik } from "formik";
import React from "react";
import {
  Button,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import * as Feather from "react-feather";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useAppState } from "../../components/App/AppProvider";
import FullLoader from "../../components/chakra/FullLoader";
import { Select } from "../../components/Forms/Field/Select";
import { Text } from "../../components/Forms/Field/Text";
import { Card } from "../../components/UI/Card/Card";
import LegacyScreenContainer from "../../components/UI/LegacyScreenContainer";
import { isJobsAccount } from "../../constants/JobsVisibleCheck";
import * as Permissions from "../../shared/constants/permission";
import * as Access from "../../utilities/access";
import * as Request from "../../utilities/request";
import { toProperCase } from "../../utilities/strings";
import useToast from "../../utilities/useToast";
import { ErrorBoundary } from "../ErrorBoundary/ErrorBoundary";

const validationSchema: any = Yup.object().shape({
  RoleName: Yup.string().min(1).required(`Name is required`),
  RoleDescription: Yup.string().min(1).required(`Description is required`),
});

const idToType: any = {
  "1d410566-8902-4e51-a5ef-7d1d53477d9b": "Issue",
  "24816703-d4bb-4e7d-89f9-341e38b4d66d": "Requirement",
  "b2662c1b-c2c4-4013-aab7-080ce019f073": "Action",
  "8d60cafe-91bb-4e5d-9b7c-739927419130": "Risk",
  "a5e5841b-7056-4ba0-8ce4-6efc73fb583f": "Job",
  "2264d816-9067-49f4-bdc2-706246e1a670": "Register",
};

const getObjectTypeGroup = (id: string): string => idToType[id];

type SingleRoleMatchParam = {
  roleID: string;
};

type SingleRoleParams = "roleID";

const getParamFromRoute = (
  match: match<SingleRoleMatchParam> | null,
  param: SingleRoleParams,
): string => (match !== null ? match.params[param] : "");

const ScreensSingleRole = () => {
  const appState = useAppState();
  const { app, auth } = appState;
  const params = useParams();
  const roleID = params.roleID || "";
  const navigate = useNavigate();
  const [state, setState] = React.useState<any>(null);
  const [pageStatus, setStatus] = React.useState<string>("Loading");
  const { displayToast } = useToast();
  const deleteRole = async (data: any) => {
    try {
      await Request.del(`settings/role/${data.RoleID}`, auth);
      displayToast({
        status: "success",
        title: `Role deleted successfully`,
      });
      navigate("/settings/roles");
    } catch (err) {
      displayToast({
        status: "error",

        title: "Failed to delete Role",
      });
      setStatus("Ready");
    }
  };
  React.useEffect(() => {
    const getState = async () => {
      try {
        // setStatus('Loading')
        const results = await Request.get(`settings/role/${roleID}`, auth);
        if (roleID === "new") {
          setState(results.data);
          setStatus("New");
        } else {
          setState(results.data);
          setStatus("Ready");
        }
      } catch (err) {
        displayToast({
          status: "error",
          title: "Failed to retrieve Role details",
        });
      }
    };

    if (auth.isLoggedIn) {
      getState();
    }
  }, [roleID]); // eslint-disable-line

  const handleRolePermissionChange = (boundData: any, event: any) => {
    console.log(state);

    const newState = boundData.data;
    // const permission = boundData.ObjectTypeGroupID === undefined ? boundData.data.Permissions["USER_SETTINGS"][boundData.PermissionType].PermissionScope : boundData.data.Permissions[getObjectTypeGroup(boundData.ObjectTypeGroupID).toUpperCase()][boundData.PermissionType].PermissionScope
    let permission;
    if (boundData.ObjectTypeGroupID === undefined || boundData.key) {
      permission =
        boundData.key === "NOTES" ||
        boundData.key === "TIME_NOTES" ||
        boundData.key === "REPORTS"
          ? boundData.data.Permissions.ADDITIONAL_FEATURES[boundData.key][
              boundData.PermissionType
            ].PermissionScope
          : boundData.data.Permissions[boundData.key][boundData.PermissionType]
              .PermissionScope;
    } else {
      permission =
        boundData.data.Permissions[
          getObjectTypeGroup(boundData.ObjectTypeGroupID).toUpperCase()
        ][boundData.PermissionType].PermissionScope;
    }

    // if type id or permission type don't exist in record status yet
    // const OID = boundData.ObjectTypeID === null ? "USER_SETTINGS" : boundData.ObjectTypeID
    let OID;
    if (boundData.ObjectTypeID === null || boundData.key) {
      OID = boundData.key;
    } else {
      OID = boundData.ObjectTypeID;
    }

    const e = typeof event === "string" ? event : event.value;
    if (!boundData.data.RolePermissions.hasOwnProperty(OID)) {
      newState.RolePermissions[OID] = {
        [boundData.PermissionType]: {},
      };
    } else if (
      !boundData.data.RolePermissions[OID].hasOwnProperty(
        boundData.PermissionType,
      )
    ) {
      newState.RolePermissions[OID][boundData.PermissionType] = {};
    }
    newState.RolePermissions[OID][boundData.PermissionType].PermissionScope = {
      ...boundData.data.RolePermissions[OID][boundData.PermissionType]
        .PermissionScope,
      changed: true,
      PermissionID: e,
      Scope:
        e === ""
          ? "None"
          : permission.find((p: any) => p.PermissionID === e).Scope,
    };

    boundData.updateData(newState);
  };

  const isPageReady = pageStatus !== "Loading";

  const pageTitle = isPageReady
    ? pageStatus === "New"
      ? "New Role"
      : `${state.RoleName}`
    : "Role";
  const breadcrumbs = [{ label: "Roles", link: "/settings/roles" }];

  return (
    <LegacyScreenContainer pageTitle={pageTitle} breadcrumbs={breadcrumbs}>
      <>
        {Access.checkAccess(
          app.permissions_LEGACY,
          Permissions.CodeUserSettings,
          Permissions.TypeRead,
          false,
        ) ? (
          isPageReady ? (
            <>
              {/* <Header
              breadcrumbs={[
                { title: "Settings", link: "/settings" },
                { title: "Roles", link: "/settings/roles" },
                {
                  title:
                    pageStatus === "New" ? "New Role" : `${state.RoleName}`,
                },
              ]}
              title={pageStatus === "New" ? "New Role" : `${state.RoleName}`}
            /> */}
              {/* We are checking */}
              <Card
                title="Details"
                collapsable={false}
                headerColsRight
                headerCols={
                  pageStatus === "Ready" &&
                  Access.checkAccess(
                    app.permissions_LEGACY,
                    Permissions.CodeUserSettings,
                    Permissions.TypeUpdate,
                    false,
                  )
                    ? [
                        {
                          colProps: { sm: "auto" },
                          children: (
                            <Col sm="auto">
                              <Button
                                type="button"
                                variant="outline-dark"
                                onClick={() => setStatus("Editing")}>
                                <Feather.Edit2 className="feather" size="16" />
                                Edit
                              </Button>
                            </Col>
                          ),
                        },
                      ]
                    : pageStatus === "Editing"
                    ? state.RoleIsUsed
                      ? [
                          {
                            colProps: { sm: "auto" },
                            children: (
                              <Col sm="auto">
                                <OverlayTrigger
                                  placement="auto"
                                  overlay={
                                    <Tooltip id="not-authorised-to-edit">
                                      You cannot delete Roles that are current
                                      in use
                                    </Tooltip>
                                  }>
                                  <Button
                                    type="button"
                                    variant="outline-dark"
                                    className="disabled">
                                    <Feather.Edit2
                                      className="feather"
                                      size="16"
                                    />
                                    Delete
                                  </Button>
                                </OverlayTrigger>
                              </Col>
                            ),
                          },
                        ]
                      : [
                          {
                            colProps: { sm: "auto" },
                            children: (
                              <Col sm="auto">
                                <DropdownButton
                                  id="dropdown-basic-button"
                                  variant="outline-dark"
                                  title="Delete">
                                  <Dropdown.Item
                                    as="button"
                                    onClick={() => deleteRole(state)}>
                                    Confirm delete
                                  </Dropdown.Item>
                                </DropdownButton>
                              </Col>
                            ),
                          },
                        ]
                    : [
                        {
                          colProps: { sm: "auto" },
                          children: (
                            <Col sm="auto">
                              <OverlayTrigger
                                placement="auto"
                                overlay={
                                  <Tooltip id="not-authorised-to-edit">
                                    You are not authorised to edit this
                                  </Tooltip>
                                }>
                                <Button
                                  type="button"
                                  variant="outline-dark"
                                  className="disabled">
                                  <Feather.Edit2
                                    className="feather"
                                    size="16"
                                  />
                                  Edit
                                </Button>
                              </OverlayTrigger>
                            </Col>
                          ),
                        },
                      ]
                }>
                <Formik
                  initialValues={state}
                  enableReinitialize
                  validationSchema={validationSchema}
                  onSubmit={async (values, actions) => {
                    // setState(values)
                    if (pageStatus === "New") {
                      try {
                        const result = await Request.put(
                          "settings/role",
                          values,
                        );
                        navigate(`/settings/role/${result.data[0].RoleID}`);
                      } catch (err) {
                        displayToast({
                          status: "error",
                          title: "Failed to create Role",
                        });
                      }
                    } else {
                      try {
                        setState(values);
                        setStatus("Submitting");

                        const result = await Request.post(
                          `settings/role/${roleID}`,
                          values,
                        );
                        displayToast({
                          status: "success",
                          title: `Role details updated successfully`,
                        });
                        window.location.reload();

                        setState(result.data);
                        setStatus("Ready");
                        actions.setSubmitting(false);
                        window.location.reload();
                      } catch (err) {
                        setStatus("Editing");
                        actions.setSubmitting(false);
                        displayToast({
                          status: "error",
                          title: "Failed to update Role details",
                        });
                      }
                    }
                  }}
                  render={(formikProps) => (
                    <Form onSubmit={formikProps.handleSubmit}>
                      <Form.Group as={Row}>
                        <Form.Label column sm="2">
                          Name
                        </Form.Label>
                        <Col sm="9">
                          <Text
                            value={formikProps.values.RoleName}
                            name="RoleName"
                            readOnly={
                              pageStatus === "Ready" ||
                              pageStatus === "Submitting"
                            }
                            onUpdate={formikProps.handleChange}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm="2">
                          Description
                        </Form.Label>
                        <Col sm="9">
                          <Text
                            value={formikProps.values.RoleDescription}
                            name="RoleDescription"
                            readOnly={
                              pageStatus === "Ready" ||
                              pageStatus === "Submitting"
                            }
                            onUpdate={formikProps.handleChange}
                          />
                        </Col>
                      </Form.Group>
                      {pageStatus === "Editing" ||
                      pageStatus === "Ready" ||
                      pageStatus !== "New" ? (
                        <>
                          <Form.Group as={Row}>
                            <Col sm={{ span: "9", offset: "2" }}>
                              <Row>
                                <Col>Create</Col>
                                <Col>Read</Col>
                                <Col>Update</Col>
                                <Col>Delete</Col>
                              </Row>
                            </Col>
                          </Form.Group>
                          {Object.keys(formikProps.values.Permissions).map(
                            (key: any, index: any) => (
                              <Form.Group key={index}>
                                {key === "USER_SETTINGS" ||
                                key === "ACCOUNT_SETTINGS" ||
                                (key === "JOB" &&
                                  !isJobsAccount(appState)) ? null : (
                                  <Form.Group
                                    as={Row}
                                    style={{
                                      backgroundColor:
                                        "var(--color-secondary-grey-light)",
                                    }}>
                                    <Form.Label column sm="2">{`${toProperCase(
                                      key,
                                    )}:`}</Form.Label>
                                    <Col sm="9">
                                      <Row />
                                    </Col>
                                  </Form.Group>
                                )}
                                {key === "USER_SETTINGS" ||
                                key === "ACCOUNT_SETTINGS" ? (
                                  <Form.Group
                                    as={Row}
                                    style={{
                                      backgroundColor:
                                        "var(--color-secondary-grey-light)",
                                    }}>
                                    <Form.Label column sm="2">{`${toProperCase(
                                      key,
                                    )}:`}</Form.Label>
                                    <Col sm="9">
                                      <Row>
                                        {formikProps.values.Permissions[key]
                                          .CREATE ? (
                                          <Col key={`${key}.CREATE`}>
                                            <Select
                                              name={`${key}.CREATE`}
                                              value={
                                                formikProps.values.RolePermissions.hasOwnProperty(
                                                  key,
                                                ) &&
                                                formikProps.values.RolePermissions[
                                                  key
                                                ].hasOwnProperty("CREATE")
                                                  ? formikProps.values
                                                      .RolePermissions[key]
                                                      .CREATE.PermissionScope
                                                      .PermissionID
                                                  : ""
                                              }
                                              optionsList={[
                                                {
                                                  Scope: "None",
                                                  PermissionID: "",
                                                },
                                              ].concat(
                                                formikProps.values.Permissions[
                                                  key
                                                ].CREATE.PermissionScope,
                                              )}
                                              optionsListID="PermissionID"
                                              optionsListValue="Scope"
                                              readOnly={
                                                pageStatus === "Ready" ||
                                                pageStatus === "Submitting"
                                              }
                                              readOnlyValue={
                                                formikProps.values.RolePermissions.hasOwnProperty(
                                                  key,
                                                ) &&
                                                formikProps.values.RolePermissions[
                                                  key
                                                ].hasOwnProperty("CREATE")
                                                  ? formikProps.values
                                                      .RolePermissions[key]
                                                      .CREATE.PermissionScope
                                                      .Scope
                                                  : "None"
                                              }
                                              onUpdate={handleRolePermissionChange.bind(
                                                {},
                                                {
                                                  data: formikProps.values,
                                                  updateData:
                                                    formikProps.setValues,
                                                  ObjectTypeID: null,
                                                  key,
                                                  PermissionType: "CREATE",
                                                },
                                              )}
                                            />
                                          </Col>
                                        ) : (
                                          <Col />
                                        )}
                                        {formikProps.values.Permissions[key]
                                          .READ ? (
                                          <Col key={`${key}.READ`}>
                                            <Select
                                              name={`${key}.READ`}
                                              value={
                                                formikProps.values.RolePermissions.hasOwnProperty(
                                                  key,
                                                ) &&
                                                formikProps.values.RolePermissions[
                                                  key
                                                ].hasOwnProperty("READ")
                                                  ? formikProps.values
                                                      .RolePermissions[key].READ
                                                      .PermissionScope
                                                      .PermissionID
                                                  : ""
                                              }
                                              optionsList={[
                                                {
                                                  Scope: "None",
                                                  PermissionID: "",
                                                },
                                              ].concat(
                                                formikProps.values.Permissions[
                                                  key
                                                ].READ.PermissionScope,
                                              )}
                                              optionsListID="PermissionID"
                                              optionsListValue="Scope"
                                              readOnly={
                                                pageStatus === "Ready" ||
                                                pageStatus === "Submitting"
                                              }
                                              readOnlyValue={
                                                formikProps.values.RolePermissions.hasOwnProperty(
                                                  key,
                                                ) &&
                                                formikProps.values.RolePermissions[
                                                  key
                                                ].hasOwnProperty("READ")
                                                  ? formikProps.values
                                                      .RolePermissions[key].READ
                                                      .PermissionScope.Scope
                                                  : "None"
                                              }
                                              onUpdate={handleRolePermissionChange.bind(
                                                {},
                                                {
                                                  data: formikProps.values,
                                                  updateData:
                                                    formikProps.setValues,
                                                  ObjectTypeID: null,
                                                  key,
                                                  PermissionType: "READ",
                                                },
                                              )}
                                            />
                                          </Col>
                                        ) : (
                                          <Col />
                                        )}
                                        {formikProps.values.Permissions[key]
                                          .UPDATE ? (
                                          <Col key={`${key}.UPDATE`}>
                                            <Select
                                              name={`${key}.UPDATE`}
                                              value={
                                                formikProps.values.RolePermissions.hasOwnProperty(
                                                  key,
                                                ) &&
                                                formikProps.values.RolePermissions[
                                                  key
                                                ].hasOwnProperty("UPDATE")
                                                  ? formikProps.values
                                                      .RolePermissions[key]
                                                      .UPDATE.PermissionScope
                                                      .PermissionID
                                                  : ""
                                              }
                                              optionsList={[
                                                {
                                                  Scope: "None",
                                                  PermissionID: "",
                                                },
                                              ].concat(
                                                formikProps.values.Permissions[
                                                  key
                                                ].UPDATE.PermissionScope,
                                              )}
                                              optionsListID="PermissionID"
                                              optionsListValue="Scope"
                                              readOnly={
                                                pageStatus === "Ready" ||
                                                pageStatus === "Submitting"
                                              }
                                              readOnlyValue={
                                                formikProps.values.RolePermissions.hasOwnProperty(
                                                  key,
                                                ) &&
                                                formikProps.values.RolePermissions[
                                                  key
                                                ].hasOwnProperty("UPDATE")
                                                  ? formikProps.values
                                                      .RolePermissions[key]
                                                      .UPDATE.PermissionScope
                                                      .Scope
                                                  : "None"
                                              }
                                              onUpdate={(event: any) => {
                                                const e = event.value;
                                                const value =
                                                  e === ""
                                                    ? ""
                                                    : formikProps.values.Permissions[
                                                        key
                                                      ].UPDATE.PermissionScope.find(
                                                        (p: any) =>
                                                          p.PermissionID === e,
                                                      ).Scope;
                                                if (e === "") {
                                                  handleRolePermissionChange(
                                                    {
                                                      data: formikProps.values,
                                                      updateData:
                                                        formikProps.setValues,
                                                      ObjectTypeID: null,
                                                      key,
                                                      PermissionType: "UPDATE",
                                                    },
                                                    e,
                                                  );
                                                  if (
                                                    (formikProps.values
                                                      .RolePermissions[key]
                                                      .DELETE &&
                                                      formikProps.values
                                                        .RolePermissions[key]
                                                        .DELETE.PermissionScope
                                                        .Scope !== "ALL") ||
                                                    formikProps.values
                                                      .RolePermissions[key]
                                                      .DELETE === undefined
                                                  ) {
                                                    handleRolePermissionChange(
                                                      {
                                                        data: formikProps.values,
                                                        updateData:
                                                          formikProps.setValues,
                                                        ObjectTypeID: null,
                                                        key,
                                                        PermissionType:
                                                          "DELETE",
                                                      },
                                                      e,
                                                    );
                                                  }
                                                } else {
                                                  handleRolePermissionChange(
                                                    {
                                                      data: formikProps.values,
                                                      updateData:
                                                        formikProps.setValues,
                                                      ObjectTypeID: null,
                                                      key,
                                                      PermissionType: "UPDATE",
                                                    },
                                                    e,
                                                  );
                                                  if (
                                                    formikProps.values
                                                      .RolePermissions[key]
                                                      .DELETE !== undefined
                                                  ) {
                                                    handleRolePermissionChange(
                                                      {
                                                        data: formikProps.values,
                                                        updateData:
                                                          formikProps.setValues,
                                                        ObjectTypeID: null,
                                                        key,
                                                        PermissionType:
                                                          "DELETE",
                                                      },
                                                      formikProps.values.Permissions[
                                                        key
                                                      ].DELETE.PermissionScope.find(
                                                        (p: any) =>
                                                          p.Scope === value,
                                                      ).PermissionID,
                                                    );
                                                  }
                                                  if (
                                                    formikProps.values
                                                      .RolePermissions[key]
                                                      .READ &&
                                                    formikProps.values
                                                      .RolePermissions[key].READ
                                                      .PermissionScope.Scope !==
                                                      "ALL"
                                                  ) {
                                                    handleRolePermissionChange(
                                                      {
                                                        data: formikProps.values,
                                                        updateData:
                                                          formikProps.setValues,
                                                        ObjectTypeID: null,
                                                        key,
                                                        PermissionType: "READ",
                                                      },
                                                      formikProps.values.Permissions[
                                                        key
                                                      ].READ.PermissionScope.find(
                                                        (p: any) =>
                                                          p.Scope === value,
                                                      ).PermissionID,
                                                    );
                                                  }
                                                }
                                              }}
                                            />
                                          </Col>
                                        ) : (
                                          <Col />
                                        )}
                                        {formikProps.values.Permissions[key]
                                          .DELETE ? (
                                          <Col key={`${key}.DELETE`}>
                                            <Select
                                              name={`${key}.DELETE`}
                                              value={
                                                formikProps.values.RolePermissions.hasOwnProperty(
                                                  key,
                                                ) &&
                                                formikProps.values.RolePermissions[
                                                  key
                                                ].hasOwnProperty("DELETE")
                                                  ? formikProps.values
                                                      .RolePermissions[key]
                                                      .DELETE.PermissionScope
                                                      .PermissionID
                                                  : ""
                                              }
                                              optionsList={[
                                                {
                                                  Scope: "None",
                                                  PermissionID: "",
                                                },
                                              ].concat(
                                                formikProps.values.Permissions[
                                                  key
                                                ].DELETE.PermissionScope,
                                              )}
                                              optionsListID="PermissionID"
                                              optionsListValue="Scope"
                                              readOnly={
                                                pageStatus === "Ready" ||
                                                pageStatus === "Submitting"
                                              }
                                              readOnlyValue={
                                                formikProps.values.RolePermissions.hasOwnProperty(
                                                  key,
                                                ) &&
                                                formikProps.values.RolePermissions[
                                                  key
                                                ].hasOwnProperty("DELETE")
                                                  ? formikProps.values
                                                      .RolePermissions[key]
                                                      .DELETE.PermissionScope
                                                      .Scope
                                                  : "None"
                                              }
                                              onUpdate={(event: any) => {
                                                const e = event.value;
                                                const value =
                                                  e === ""
                                                    ? ""
                                                    : formikProps.values.Permissions[
                                                        key
                                                      ].DELETE.PermissionScope.find(
                                                        (p: any) =>
                                                          p.PermissionID === e,
                                                      ).Scope;
                                                if (
                                                  value === "ALL" &&
                                                  (formikProps.values
                                                    .RolePermissions[key] ===
                                                    undefined ||
                                                    (formikProps.values
                                                      .RolePermissions[key]
                                                      .UPDATE &&
                                                      formikProps.values
                                                        .RolePermissions[key]
                                                        .UPDATE.PermissionScope
                                                        .Scope !== "ALL"))
                                                ) {
                                                  handleRolePermissionChange(
                                                    {
                                                      data: formikProps.values,
                                                      updateData:
                                                        formikProps.setValues,
                                                      ObjectTypeID: null,
                                                      key,
                                                      PermissionType: "DELETE",
                                                    },
                                                    e,
                                                  );
                                                  handleRolePermissionChange(
                                                    {
                                                      data: formikProps.values,
                                                      updateData:
                                                        formikProps.setValues,
                                                      ObjectTypeID: null,
                                                      key,
                                                      PermissionType: "UPDATE",
                                                    },
                                                    e === ""
                                                      ? ""
                                                      : formikProps.values.Permissions[
                                                          key
                                                        ].UPDATE.PermissionScope.find(
                                                          (p: any) =>
                                                            p.Scope === value,
                                                        ).PermissionID,
                                                  );
                                                  handleRolePermissionChange(
                                                    {
                                                      data: formikProps.values,
                                                      updateData:
                                                        formikProps.setValues,
                                                      ObjectTypeID: null,
                                                      key,
                                                      PermissionType: "READ",
                                                    },
                                                    e === ""
                                                      ? ""
                                                      : formikProps.values.Permissions[
                                                          key
                                                        ].READ.PermissionScope.find(
                                                          (p: any) =>
                                                            p.Scope === value,
                                                        ).PermissionID,
                                                  );
                                                } else if (
                                                  value === "SELF" &&
                                                  formikProps.values
                                                    .RolePermissions[key] ===
                                                    undefined
                                                ) {
                                                  handleRolePermissionChange(
                                                    {
                                                      data: formikProps.values,
                                                      updateData:
                                                        formikProps.setValues,
                                                      ObjectTypeID: null,
                                                      key,
                                                      PermissionType: "DELETE",
                                                    },
                                                    e,
                                                  );
                                                  handleRolePermissionChange(
                                                    {
                                                      data: formikProps.values,
                                                      updateData:
                                                        formikProps.setValues,
                                                      ObjectTypeID: null,
                                                      key,
                                                      PermissionType: "UPDATE",
                                                    },
                                                    e === ""
                                                      ? ""
                                                      : formikProps.values.Permissions[
                                                          key
                                                        ].UPDATE.PermissionScope.find(
                                                          (p: any) =>
                                                            p.Scope === value,
                                                        ).PermissionID,
                                                  );
                                                  handleRolePermissionChange(
                                                    {
                                                      data: formikProps.values,
                                                      updateData:
                                                        formikProps.setValues,
                                                      ObjectTypeID: null,
                                                      key,
                                                      PermissionType: "READ",
                                                    },
                                                    e === ""
                                                      ? ""
                                                      : formikProps.values.Permissions[
                                                          key
                                                        ].READ.PermissionScope.find(
                                                          (p: any) =>
                                                            p.Scope === value,
                                                        ).PermissionID,
                                                  );
                                                } else if (
                                                  formikProps.values
                                                    .RolePermissions[key]
                                                    .UPDATE === undefined &&
                                                  formikProps.values
                                                    .RolePermissions[key] !==
                                                    undefined
                                                ) {
                                                  handleRolePermissionChange(
                                                    {
                                                      data: formikProps.values,
                                                      updateData:
                                                        formikProps.setValues,
                                                      ObjectTypeID: null,
                                                      key,
                                                      PermissionType: "DELETE",
                                                    },
                                                    e,
                                                  );
                                                  handleRolePermissionChange(
                                                    {
                                                      data: formikProps.values,
                                                      updateData:
                                                        formikProps.setValues,
                                                      ObjectTypeID: null,
                                                      key,
                                                      PermissionType: "UPDATE",
                                                    },
                                                    e === ""
                                                      ? ""
                                                      : formikProps.values.Permissions[
                                                          key
                                                        ].UPDATE.PermissionScope.find(
                                                          (p: any) =>
                                                            p.Scope === value,
                                                        ).PermissionID,
                                                  );
                                                  if (
                                                    formikProps.values
                                                      .RolePermissions[key]
                                                      .READ &&
                                                    formikProps.values
                                                      .RolePermissions[key].READ
                                                      .PermissionScope.Scope !==
                                                      "ALL"
                                                  ) {
                                                    handleRolePermissionChange(
                                                      {
                                                        data: formikProps.values,
                                                        updateData:
                                                          formikProps.setValues,
                                                        ObjectTypeID: null,
                                                        key,
                                                        PermissionType: "READ",
                                                      },
                                                      e === ""
                                                        ? ""
                                                        : formikProps.values.Permissions[
                                                            key
                                                          ].READ.PermissionScope.find(
                                                            (p: any) =>
                                                              p.Scope === value,
                                                          ).PermissionID,
                                                    );
                                                  }
                                                } else if (
                                                  (formikProps.values
                                                    .RolePermissions[key]
                                                    .UPDATE !== undefined &&
                                                    formikProps.values
                                                      .RolePermissions[key]
                                                      .UPDATE.PermissionScope
                                                      .Scope === "ALL") ||
                                                  formikProps.values
                                                    .RolePermissions[key].UPDATE
                                                    .PermissionScope.Scope ===
                                                    "SELF"
                                                ) {
                                                  handleRolePermissionChange(
                                                    {
                                                      data: formikProps.values,
                                                      updateData:
                                                        formikProps.setValues,
                                                      ObjectTypeID: null,
                                                      key,
                                                      PermissionType: "DELETE",
                                                    },
                                                    e,
                                                  );
                                                }
                                              }}
                                            />
                                          </Col>
                                        ) : (
                                          <Col />
                                        )}
                                      </Row>
                                    </Col>
                                  </Form.Group>
                                ) : key === "ADDITIONAL_FEATURES" ? (
                                  Object.keys(
                                    formikProps.values.Permissions[key],
                                  ).map((feature: any) => (
                                    <Form.Group
                                      as={Row}
                                      key={`feature-${feature}`}
                                      style={{ backgroundColor: "inherit" }}>
                                      <Form.Label column sm="2">
                                        {toProperCase(feature)}
                                      </Form.Label>
                                      <Col sm="9">
                                        <Row>
                                          {Array.from([
                                            "CREATE",
                                            "READ",
                                            "UPDATE",
                                            "DELETE",
                                          ]).map((permissionType: string) => (
                                            <Col
                                              key={`${feature}-${permissionType}`}>
                                              {formikProps.values.Permissions[
                                                key
                                              ][feature].hasOwnProperty(
                                                permissionType,
                                              ) ? (
                                                <Select
                                                  name={`${feature}.${permissionType}`}
                                                  value={
                                                    formikProps.values.RolePermissions.hasOwnProperty(
                                                      feature,
                                                    ) &&
                                                    formikProps.values.RolePermissions[
                                                      feature
                                                    ].hasOwnProperty(
                                                      permissionType,
                                                    )
                                                      ? formikProps.values
                                                          .RolePermissions[
                                                          feature
                                                        ][permissionType]
                                                          .PermissionScope
                                                          .PermissionID
                                                      : ""
                                                  }
                                                  optionsList={[
                                                    {
                                                      Scope: "None",
                                                      PermissionID: "",
                                                    },
                                                  ].concat(
                                                    formikProps.values
                                                      .Permissions[key][
                                                      feature
                                                    ][permissionType]
                                                      .PermissionScope,
                                                  )}
                                                  optionsListID="PermissionID"
                                                  optionsListValue="Scope"
                                                  readOnly={
                                                    pageStatus === "Ready" ||
                                                    pageStatus === "Submitting"
                                                  }
                                                  readOnlyValue={
                                                    formikProps.values.RolePermissions.hasOwnProperty(
                                                      feature,
                                                    ) &&
                                                    formikProps.values.RolePermissions[
                                                      feature
                                                    ].hasOwnProperty(
                                                      permissionType,
                                                    )
                                                      ? formikProps.values
                                                          .RolePermissions[
                                                          feature
                                                        ][permissionType]
                                                          .PermissionScope.Scope
                                                      : "None"
                                                  }
                                                  onUpdate={(event: any) => {
                                                    const e = event.value;
                                                    if (
                                                      permissionType ===
                                                      "DELETE"
                                                    ) {
                                                      const value =
                                                        e === ""
                                                          ? ""
                                                          : formikProps.values.Permissions[
                                                              key
                                                            ][
                                                              feature
                                                            ].DELETE.PermissionScope.find(
                                                              (p: any) =>
                                                                p.PermissionID ===
                                                                e,
                                                            ).Scope;
                                                      if (
                                                        (value === "ALL" ||
                                                          value === "SELF") &&
                                                        formikProps.values
                                                          .RolePermissions[
                                                          feature
                                                        ] === undefined
                                                      ) {
                                                        handleRolePermissionChange(
                                                          {
                                                            data: formikProps.values,
                                                            updateData:
                                                              formikProps.setValues,
                                                            ObjectTypeID: null,
                                                            key: feature,
                                                            PermissionType:
                                                              "DELETE",
                                                          },
                                                          e,
                                                        );
                                                        handleRolePermissionChange(
                                                          {
                                                            data: formikProps.values,
                                                            updateData:
                                                              formikProps.setValues,
                                                            ObjectTypeID: null,
                                                            key: feature,
                                                            PermissionType:
                                                              "READ",
                                                          },
                                                          e === ""
                                                            ? ""
                                                            : formikProps.values.Permissions[
                                                                key
                                                              ][
                                                                feature
                                                              ].READ.PermissionScope.find(
                                                                (p: any) =>
                                                                  p.Scope ===
                                                                  value,
                                                              ).PermissionID,
                                                        );
                                                      } else if (
                                                        formikProps.values
                                                          .RolePermissions[
                                                          feature
                                                        ] !== undefined
                                                      ) {
                                                        handleRolePermissionChange(
                                                          {
                                                            data: formikProps.values,
                                                            updateData:
                                                              formikProps.setValues,
                                                            ObjectTypeID: null,
                                                            key: feature,
                                                            PermissionType:
                                                              "DELETE",
                                                          },
                                                          e,
                                                        );
                                                        if (
                                                          value &&
                                                          (formikProps.values
                                                            .RolePermissions[
                                                            feature
                                                          ].READ ===
                                                            undefined ||
                                                            formikProps.values
                                                              .RolePermissions[
                                                              feature
                                                            ].READ
                                                              .PermissionScope
                                                              .Scope !== value)
                                                        ) {
                                                          handleRolePermissionChange(
                                                            {
                                                              data: formikProps.values,
                                                              updateData:
                                                                formikProps.setValues,
                                                              ObjectTypeID:
                                                                null,
                                                              key: feature,
                                                              PermissionType:
                                                                "READ",
                                                            },
                                                            e === ""
                                                              ? ""
                                                              : formikProps.values.Permissions[
                                                                  key
                                                                ][
                                                                  feature
                                                                ].READ.PermissionScope.find(
                                                                  (p: any) =>
                                                                    p.Scope ===
                                                                    value,
                                                                ).PermissionID,
                                                          );
                                                        }
                                                      }
                                                    } else {
                                                      handleRolePermissionChange(
                                                        {
                                                          data: formikProps.values,
                                                          updateData:
                                                            formikProps.setValues,
                                                          ObjectTypeID: null,
                                                          key: feature,
                                                          PermissionType:
                                                            permissionType,
                                                        },
                                                        e,
                                                      );
                                                    }
                                                  }}
                                                />
                                              ) : null}
                                            </Col>
                                          ))}
                                        </Row>
                                      </Col>
                                    </Form.Group>
                                  ))
                                ) : (
                                  formikProps.values.ObjectTypes.map(
                                    (objectType: any) => {
                                      if (
                                        getObjectTypeGroup(
                                          objectType.ObjectTypeGroupID,
                                        ).toUpperCase() === key &&
                                        (key === "JOB"
                                          ? isJobsAccount(appState)
                                          : true)
                                      ) {
                                        return (
                                          <Form.Group
                                            as={Row}
                                            key={objectType.ObjectTypeID}
                                            style={{
                                              backgroundColor: "inherit",
                                            }}>
                                            <Form.Label
                                              column
                                              sm="2">{`${objectType.ObjectTypeName}`}</Form.Label>
                                            <Col sm="9">
                                              <Row>
                                                {formikProps.values.Permissions[
                                                  key
                                                ].CREATE ? (
                                                  <Col
                                                    key={`${objectType.ObjectTypeID}.CREATE`}>
                                                    <Select
                                                      name={`${objectType.ObjectTypeID}.CREATE`}
                                                      value={
                                                        formikProps.values.RolePermissions.hasOwnProperty(
                                                          objectType.ObjectTypeID,
                                                        ) &&
                                                        formikProps.values.RolePermissions[
                                                          objectType
                                                            .ObjectTypeID
                                                        ].hasOwnProperty(
                                                          "CREATE",
                                                        )
                                                          ? formikProps.values
                                                              .RolePermissions[
                                                              objectType
                                                                .ObjectTypeID
                                                            ].CREATE
                                                              .PermissionScope
                                                              .PermissionID
                                                          : ""
                                                      }
                                                      optionsList={[
                                                        {
                                                          Scope: "None",
                                                          PermissionID: "",
                                                        },
                                                      ].concat(
                                                        formikProps.values
                                                          .Permissions[key]
                                                          .CREATE
                                                          .PermissionScope,
                                                      )}
                                                      optionsListID="PermissionID"
                                                      optionsListValue="Scope"
                                                      readOnly={
                                                        pageStatus ===
                                                          "Ready" ||
                                                        pageStatus ===
                                                          "Submitting"
                                                      }
                                                      readOnlyValue={
                                                        formikProps.values.RolePermissions.hasOwnProperty(
                                                          objectType.ObjectTypeID,
                                                        ) &&
                                                        formikProps.values.RolePermissions[
                                                          objectType
                                                            .ObjectTypeID
                                                        ].hasOwnProperty(
                                                          "CREATE",
                                                        )
                                                          ? formikProps.values
                                                              .RolePermissions[
                                                              objectType
                                                                .ObjectTypeID
                                                            ].CREATE
                                                              .PermissionScope
                                                              .Scope
                                                          : "None"
                                                      }
                                                      onUpdate={handleRolePermissionChange.bind(
                                                        {},
                                                        {
                                                          data: formikProps.values,
                                                          updateData:
                                                            formikProps.setValues,
                                                          ObjectTypeID:
                                                            objectType.ObjectTypeID,
                                                          ObjectTypeGroupID:
                                                            objectType.ObjectTypeGroupID,
                                                          PermissionType:
                                                            "CREATE",
                                                        },
                                                      )}
                                                    />
                                                  </Col>
                                                ) : (
                                                  <Col />
                                                )}
                                                {formikProps.values.Permissions[
                                                  key
                                                ].READ ? (
                                                  <Col
                                                    key={`${objectType.ObjectTypeID}.READ`}>
                                                    <Select
                                                      name={`${objectType.ObjectTypeID}.READ`}
                                                      value={
                                                        formikProps.values.RolePermissions.hasOwnProperty(
                                                          objectType.ObjectTypeID,
                                                        ) &&
                                                        formikProps.values.RolePermissions[
                                                          objectType
                                                            .ObjectTypeID
                                                        ].hasOwnProperty("READ")
                                                          ? formikProps.values
                                                              .RolePermissions[
                                                              objectType
                                                                .ObjectTypeID
                                                            ].READ
                                                              .PermissionScope
                                                              .PermissionID
                                                          : ""
                                                      }
                                                      optionsList={[
                                                        {
                                                          Scope: "None",
                                                          PermissionID: "",
                                                        },
                                                      ].concat(
                                                        formikProps.values
                                                          .Permissions[key].READ
                                                          .PermissionScope,
                                                      )}
                                                      optionsListID="PermissionID"
                                                      optionsListValue="Scope"
                                                      readOnly={
                                                        pageStatus ===
                                                          "Ready" ||
                                                        pageStatus ===
                                                          "Submitting"
                                                      }
                                                      readOnlyValue={
                                                        formikProps.values.RolePermissions.hasOwnProperty(
                                                          objectType.ObjectTypeID,
                                                        ) &&
                                                        formikProps.values.RolePermissions[
                                                          objectType
                                                            .ObjectTypeID
                                                        ].hasOwnProperty("READ")
                                                          ? formikProps.values
                                                              .RolePermissions[
                                                              objectType
                                                                .ObjectTypeID
                                                            ].READ
                                                              .PermissionScope
                                                              .Scope
                                                          : "None"
                                                      }
                                                      onUpdate={handleRolePermissionChange.bind(
                                                        {},
                                                        {
                                                          data: formikProps.values,
                                                          updateData:
                                                            formikProps.setValues,
                                                          ObjectTypeID:
                                                            objectType.ObjectTypeID,
                                                          ObjectTypeGroupID:
                                                            objectType.ObjectTypeGroupID,
                                                          PermissionType:
                                                            "READ",
                                                        },
                                                      )}
                                                    />
                                                  </Col>
                                                ) : (
                                                  <Col />
                                                )}
                                                {formikProps.values.Permissions[
                                                  key
                                                ].UPDATE ? (
                                                  <Col
                                                    key={`${objectType.ObjectTypeID}.UPDATE`}>
                                                    <Select
                                                      name={`${objectType.ObjectTypeID}.UPDATE`}
                                                      value={
                                                        formikProps.values.RolePermissions.hasOwnProperty(
                                                          objectType.ObjectTypeID,
                                                        ) &&
                                                        formikProps.values.RolePermissions[
                                                          objectType
                                                            .ObjectTypeID
                                                        ].hasOwnProperty(
                                                          "UPDATE",
                                                        )
                                                          ? formikProps.values
                                                              .RolePermissions[
                                                              objectType
                                                                .ObjectTypeID
                                                            ].UPDATE
                                                              .PermissionScope
                                                              .PermissionID
                                                          : ""
                                                      }
                                                      optionsList={[
                                                        {
                                                          Scope: "None",
                                                          PermissionID: "",
                                                        },
                                                      ].concat(
                                                        formikProps.values
                                                          .Permissions[key]
                                                          .UPDATE
                                                          .PermissionScope,
                                                      )}
                                                      optionsListID="PermissionID"
                                                      optionsListValue="Scope"
                                                      readOnly={
                                                        pageStatus ===
                                                          "Ready" ||
                                                        pageStatus ===
                                                          "Submitting"
                                                      }
                                                      readOnlyValue={
                                                        formikProps.values.RolePermissions.hasOwnProperty(
                                                          objectType.ObjectTypeID,
                                                        ) &&
                                                        formikProps.values.RolePermissions[
                                                          objectType
                                                            .ObjectTypeID
                                                        ].hasOwnProperty(
                                                          "UPDATE",
                                                        )
                                                          ? formikProps.values
                                                              .RolePermissions[
                                                              objectType
                                                                .ObjectTypeID
                                                            ].UPDATE
                                                              .PermissionScope
                                                              .Scope
                                                          : "None"
                                                      }
                                                      onUpdate={(
                                                        event: any,
                                                      ) => {
                                                        const e = event.value;
                                                        console.log(e);
                                                        const value =
                                                          e === ""
                                                            ? ""
                                                            : formikProps.values.Permissions[
                                                                key
                                                              ].UPDATE.PermissionScope.find(
                                                                (p: any) =>
                                                                  p.PermissionID ===
                                                                  e,
                                                              ).Scope;
                                                        console.log(
                                                          "value",
                                                          value,
                                                        );
                                                        if (e === "") {
                                                          handleRolePermissionChange(
                                                            {
                                                              data: formikProps.values,
                                                              updateData:
                                                                formikProps.setValues,
                                                              ObjectTypeID:
                                                                objectType.ObjectTypeID,
                                                              ObjectTypeGroupID:
                                                                objectType.ObjectTypeGroupID,
                                                              PermissionType:
                                                                "UPDATE",
                                                            },
                                                            e,
                                                          );
                                                          handleRolePermissionChange(
                                                            {
                                                              data: formikProps.values,
                                                              updateData:
                                                                formikProps.setValues,
                                                              ObjectTypeID:
                                                                objectType.ObjectTypeID,
                                                              ObjectTypeGroupID:
                                                                objectType.ObjectTypeGroupID,
                                                              PermissionType:
                                                                "DELETE",
                                                            },
                                                            e,
                                                          );
                                                        } else {
                                                          handleRolePermissionChange(
                                                            {
                                                              data: formikProps.values,
                                                              updateData:
                                                                formikProps.setValues,
                                                              ObjectTypeID:
                                                                objectType.ObjectTypeID,
                                                              ObjectTypeGroupID:
                                                                objectType.ObjectTypeGroupID,
                                                              PermissionType:
                                                                "UPDATE",
                                                            },
                                                            e,
                                                          );
                                                          handleRolePermissionChange(
                                                            {
                                                              data: formikProps.values,
                                                              updateData:
                                                                formikProps.setValues,
                                                              ObjectTypeID:
                                                                objectType.ObjectTypeID,
                                                              ObjectTypeGroupID:
                                                                objectType.ObjectTypeGroupID,
                                                              PermissionType:
                                                                "DELETE",
                                                            },
                                                            formikProps.values.Permissions[
                                                              key
                                                            ].DELETE.PermissionScope.find(
                                                              (p: any) =>
                                                                p.Scope ===
                                                                value,
                                                            ).PermissionID,
                                                          );
                                                          if (
                                                            (formikProps.values
                                                              .RolePermissions[
                                                              objectType
                                                                .ObjectTypeID
                                                            ].READ &&
                                                              formikProps.values
                                                                .RolePermissions[
                                                                objectType
                                                                  .ObjectTypeID
                                                              ].READ
                                                                .PermissionScope
                                                                .Scope !==
                                                                "ALL") ||
                                                            formikProps.values
                                                              .RolePermissions[
                                                              objectType
                                                                .ObjectTypeID
                                                            ].READ === undefined
                                                          ) {
                                                            handleRolePermissionChange(
                                                              {
                                                                data: formikProps.values,
                                                                updateData:
                                                                  formikProps.setValues,
                                                                ObjectTypeID:
                                                                  objectType.ObjectTypeID,
                                                                ObjectTypeGroupID:
                                                                  objectType.ObjectTypeGroupID,
                                                                PermissionType:
                                                                  "READ",
                                                              },
                                                              formikProps.values.Permissions[
                                                                key
                                                              ].READ.PermissionScope.find(
                                                                (p: any) =>
                                                                  p.Scope ===
                                                                  value,
                                                              ).PermissionID,
                                                            );
                                                          }
                                                        }
                                                      }}
                                                    />
                                                  </Col>
                                                ) : (
                                                  <Col />
                                                )}
                                                {formikProps.values.Permissions[
                                                  key
                                                ].DELETE ? (
                                                  <Col
                                                    key={`${objectType.ObjectTypeID}.DELETE`}>
                                                    <Select
                                                      name={`${objectType.ObjectTypeID}.DELETE`}
                                                      value={
                                                        formikProps.values.RolePermissions.hasOwnProperty(
                                                          objectType.ObjectTypeID,
                                                        ) &&
                                                        formikProps.values.RolePermissions[
                                                          objectType
                                                            .ObjectTypeID
                                                        ].hasOwnProperty(
                                                          "DELETE",
                                                        )
                                                          ? formikProps.values
                                                              .RolePermissions[
                                                              objectType
                                                                .ObjectTypeID
                                                            ].DELETE
                                                              .PermissionScope
                                                              .PermissionID
                                                          : ""
                                                      }
                                                      optionsList={[
                                                        {
                                                          Scope: "None",
                                                          PermissionID: "",
                                                        },
                                                      ].concat(
                                                        formikProps.values
                                                          .Permissions[key]
                                                          .DELETE
                                                          .PermissionScope,
                                                      )}
                                                      optionsListID="PermissionID"
                                                      optionsListValue="Scope"
                                                      readOnly={
                                                        pageStatus ===
                                                          "Ready" ||
                                                        pageStatus ===
                                                          "Submitting"
                                                      }
                                                      readOnlyValue={
                                                        formikProps.values.RolePermissions.hasOwnProperty(
                                                          objectType.ObjectTypeID,
                                                        ) &&
                                                        formikProps.values.RolePermissions[
                                                          objectType
                                                            .ObjectTypeID
                                                        ].hasOwnProperty(
                                                          "DELETE",
                                                        )
                                                          ? formikProps.values
                                                              .RolePermissions[
                                                              objectType
                                                                .ObjectTypeID
                                                            ].DELETE
                                                              .PermissionScope
                                                              .Scope
                                                          : "None"
                                                      }
                                                      onUpdate={(
                                                        event: any,
                                                      ) => {
                                                        const e = event.value;
                                                        const value =
                                                          e === ""
                                                            ? ""
                                                            : formikProps.values.Permissions[
                                                                key
                                                              ].DELETE.PermissionScope.find(
                                                                (p: any) =>
                                                                  p.PermissionID ===
                                                                  e,
                                                              ).Scope;
                                                        if (
                                                          value === "ALL" &&
                                                          (formikProps.values
                                                            .RolePermissions[
                                                            objectType
                                                              .ObjectTypeID
                                                          ] === undefined ||
                                                            (formikProps.values
                                                              .RolePermissions[
                                                              objectType
                                                                .ObjectTypeID
                                                            ].UPDATE &&
                                                              formikProps.values
                                                                .RolePermissions[
                                                                objectType
                                                                  .ObjectTypeID
                                                              ].UPDATE
                                                                .PermissionScope
                                                                .Scope !==
                                                                "ALL"))
                                                        ) {
                                                          handleRolePermissionChange(
                                                            {
                                                              data: formikProps.values,
                                                              updateData:
                                                                formikProps.setValues,
                                                              ObjectTypeID:
                                                                objectType.ObjectTypeID,
                                                              ObjectTypeGroupID:
                                                                objectType.ObjectTypeGroupID,
                                                              PermissionType:
                                                                "DELETE",
                                                            },
                                                            e,
                                                          );
                                                          handleRolePermissionChange(
                                                            {
                                                              data: formikProps.values,
                                                              updateData:
                                                                formikProps.setValues,
                                                              ObjectTypeID:
                                                                objectType.ObjectTypeID,
                                                              ObjectTypeGroupID:
                                                                objectType.ObjectTypeGroupID,
                                                              PermissionType:
                                                                "UPDATE",
                                                            },
                                                            e === ""
                                                              ? ""
                                                              : formikProps.values.Permissions[
                                                                  key
                                                                ].UPDATE.PermissionScope.find(
                                                                  (p: any) =>
                                                                    p.Scope ===
                                                                    value,
                                                                ).PermissionID,
                                                          );
                                                          handleRolePermissionChange(
                                                            {
                                                              data: formikProps.values,
                                                              updateData:
                                                                formikProps.setValues,
                                                              ObjectTypeID:
                                                                objectType.ObjectTypeID,
                                                              ObjectTypeGroupID:
                                                                objectType.ObjectTypeGroupID,
                                                              PermissionType:
                                                                "READ",
                                                            },
                                                            e === ""
                                                              ? ""
                                                              : formikProps.values.Permissions[
                                                                  key
                                                                ].READ.PermissionScope.find(
                                                                  (p: any) =>
                                                                    p.Scope ===
                                                                    value,
                                                                ).PermissionID,
                                                          );
                                                        } else if (
                                                          value === "SELF" &&
                                                          formikProps.values
                                                            .RolePermissions[
                                                            objectType
                                                              .ObjectTypeID
                                                          ] === undefined
                                                        ) {
                                                          handleRolePermissionChange(
                                                            {
                                                              data: formikProps.values,
                                                              updateData:
                                                                formikProps.setValues,
                                                              ObjectTypeID:
                                                                objectType.ObjectTypeID,
                                                              ObjectTypeGroupID:
                                                                objectType.ObjectTypeGroupID,
                                                              PermissionType:
                                                                "DELETE",
                                                            },
                                                            e,
                                                          );
                                                          handleRolePermissionChange(
                                                            {
                                                              data: formikProps.values,
                                                              updateData:
                                                                formikProps.setValues,
                                                              ObjectTypeID:
                                                                objectType.ObjectTypeID,
                                                              ObjectTypeGroupID:
                                                                objectType.ObjectTypeGroupID,
                                                              PermissionType:
                                                                "UPDATE",
                                                            },
                                                            e === ""
                                                              ? ""
                                                              : formikProps.values.Permissions[
                                                                  key
                                                                ].UPDATE.PermissionScope.find(
                                                                  (p: any) =>
                                                                    p.Scope ===
                                                                    value,
                                                                ).PermissionID,
                                                          );
                                                          handleRolePermissionChange(
                                                            {
                                                              data: formikProps.values,
                                                              updateData:
                                                                formikProps.setValues,
                                                              ObjectTypeID:
                                                                objectType.ObjectTypeID,
                                                              ObjectTypeGroupID:
                                                                objectType.ObjectTypeGroupID,
                                                              PermissionType:
                                                                "READ",
                                                            },
                                                            e === ""
                                                              ? ""
                                                              : formikProps.values.Permissions[
                                                                  key
                                                                ].READ.PermissionScope.find(
                                                                  (p: any) =>
                                                                    p.Scope ===
                                                                    value,
                                                                ).PermissionID,
                                                          );
                                                        } else if (
                                                          formikProps.values
                                                            .RolePermissions[
                                                            objectType
                                                              .ObjectTypeID
                                                          ].UPDATE ===
                                                            undefined &&
                                                          formikProps.values
                                                            .RolePermissions[
                                                            objectType
                                                              .ObjectTypeID
                                                          ] !== undefined
                                                        ) {
                                                          handleRolePermissionChange(
                                                            {
                                                              data: formikProps.values,
                                                              updateData:
                                                                formikProps.setValues,
                                                              ObjectTypeID:
                                                                objectType.ObjectTypeID,
                                                              ObjectTypeGroupID:
                                                                objectType.ObjectTypeGroupID,
                                                              PermissionType:
                                                                "DELETE",
                                                            },
                                                            e,
                                                          );
                                                          handleRolePermissionChange(
                                                            {
                                                              data: formikProps.values,
                                                              updateData:
                                                                formikProps.setValues,
                                                              ObjectTypeID:
                                                                objectType.ObjectTypeID,
                                                              ObjectTypeGroupID:
                                                                objectType.ObjectTypeGroupID,
                                                              PermissionType:
                                                                "UPDATE",
                                                            },
                                                            e === ""
                                                              ? ""
                                                              : formikProps.values.Permissions[
                                                                  key
                                                                ].UPDATE.PermissionScope.find(
                                                                  (p: any) =>
                                                                    p.Scope ===
                                                                    value,
                                                                ).PermissionID,
                                                          );
                                                          if (
                                                            formikProps.values
                                                              .RolePermissions[
                                                              objectType
                                                                .ObjectTypeID
                                                            ].READ &&
                                                            formikProps.values
                                                              .RolePermissions[
                                                              objectType
                                                                .ObjectTypeID
                                                            ].READ
                                                              .PermissionScope
                                                              .Scope !== "ALL"
                                                          ) {
                                                            handleRolePermissionChange(
                                                              {
                                                                data: formikProps.values,
                                                                updateData:
                                                                  formikProps.setValues,
                                                                ObjectTypeID:
                                                                  objectType.ObjectTypeID,
                                                                ObjectTypeGroupID:
                                                                  objectType.ObjectTypeGroupID,
                                                                PermissionType:
                                                                  "READ",
                                                              },
                                                              e === ""
                                                                ? ""
                                                                : formikProps.values.Permissions[
                                                                    key
                                                                  ].READ.PermissionScope.find(
                                                                    (p: any) =>
                                                                      p.Scope ===
                                                                      value,
                                                                  )
                                                                    .PermissionID,
                                                            );
                                                          }
                                                        } else if (
                                                          (formikProps.values
                                                            .RolePermissions[
                                                            objectType
                                                              .ObjectTypeID
                                                          ].UPDATE !==
                                                            undefined &&
                                                            formikProps.values
                                                              .RolePermissions[
                                                              objectType
                                                                .ObjectTypeID
                                                            ].UPDATE
                                                              .PermissionScope
                                                              .Scope ===
                                                              "ALL") ||
                                                          formikProps.values
                                                            .RolePermissions[
                                                            objectType
                                                              .ObjectTypeID
                                                          ].UPDATE
                                                            .PermissionScope
                                                            .Scope === "SELF"
                                                        ) {
                                                          handleRolePermissionChange(
                                                            {
                                                              data: formikProps.values,
                                                              updateData:
                                                                formikProps.setValues,
                                                              ObjectTypeID:
                                                                objectType.ObjectTypeID,
                                                              ObjectTypeGroupID:
                                                                objectType.ObjectTypeGroupID,
                                                              PermissionType:
                                                                "DELETE",
                                                            },
                                                            e,
                                                          );
                                                        }
                                                      }}
                                                    />
                                                  </Col>
                                                ) : (
                                                  <Col />
                                                )}
                                              </Row>
                                            </Col>
                                          </Form.Group>
                                        );
                                      }
                                      return null;
                                    },
                                  )
                                )}
                              </Form.Group>
                            ),
                          )}
                        </>
                      ) : null}
                      <Form.Group as={Row}>
                        {pageStatus === "Editing" || pageStatus === "New" ? (
                          <>
                            <Col sm={{ span: "auto", offset: "2" }}>
                              <Button
                                type="submit"
                                onClick={() => {
                                  if (pageStatus === "New") {
                                    const errors = JSON.stringify(
                                      formikProps.errors,
                                    )
                                      .replace("{", "")
                                      .replace("}", "")
                                      .split(",");
                                    errors.map(
                                      (e: string, index: number) =>
                                        (errors[index] = e
                                          .substring(e.lastIndexOf(":") + 1)
                                          .replace("}", "")),
                                    );
                                    if (
                                      (errors.length > 0 && errors[0] !== "") ||
                                      (errors.length > 1 && errors[0] === "")
                                    ) {
                                      const title = "Failed to create Role";
                                      const description = `${errors.join(
                                        "\n",
                                      )}`;
                                      displayToast({
                                        status: "error",
                                        title,
                                        description,
                                      });
                                    } else if (
                                      formikProps.values.RoleName === "" &&
                                      formikProps.values.RoleDescription ===
                                        "" &&
                                      formikProps.isSubmitting === false
                                    ) {
                                      errors.push(
                                        `Name is required`,
                                        `Description is required`,
                                      );
                                      const title = "Failed to create Role";
                                      const description = `{errors.join(
                                      "\n",
                                    )}`;
                                      displayToast({
                                        status: "error",
                                        title,
                                        description,
                                      });
                                    }
                                  }
                                }}>
                                Submit
                              </Button>
                            </Col>
                            <Col sm="auto">
                              <Button
                                type="button"
                                variant="light"
                                onClick={() => {
                                  if (pageStatus === "Editing") {
                                    // window.location.reload() //can be an option in the future if we want to cancel multiple times
                                    formikProps.resetForm();
                                    setStatus("Ready");
                                  } else {
                                    navigate("/settings/roles");
                                  }
                                }}>
                                Cancel
                              </Button>
                            </Col>
                          </>
                        ) : null}
                      </Form.Group>
                    </Form>
                  )}
                />
              </Card>
            </>
          ) : (
            <FullLoader />
          )
        ) : (
          <ErrorBoundary>
            <div />
          </ErrorBoundary>
        )}
      </>
    </LegacyScreenContainer>
  );
};

export { ScreensSingleRole };
