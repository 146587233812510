import {
  Box,
  Button,
  ButtonGroup,
  Heading,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from "@chakra-ui/react";
import { EditorContent } from "@tiptap/react";
import { forwardRef } from "react";
import { RiEyeLine, RiPencilLine } from "react-icons/ri";
import { DeleteIcon, MoreMenuIcon } from "../../../../constants/commonIcons";
import { useEditorWithExtensions } from "./useEditorWithExtensions";
import { DateString } from "../../../../shared/v2/dateString";
import { formatDateOrIsoString } from "../../../../utilities/dateUtils";

type Props = {
  title: string;
  contentJSON: string;
  sectionHTMLID: string;
  responsibleUser: {
    firstName: string;
    lastName: string;
    userID: string;
  };
  modified: {
    date: DateString;
    firstName: string;
    lastName: string;
    userID: string;
  };
  onClickEdit: () => void;
  onClickDelete: () => void;
};

const ManualSection = forwardRef<HTMLDivElement, Props>(
  (
    { onClickDelete, responsibleUser, modified, title, contentJSON, sectionHTMLID, onClickEdit },
    headingRef,
  ) => {
    const readonlyEditor = useEditorWithExtensions({
      content: contentJSON.length >= 1 ? JSON.parse(contentJSON) : undefined,
      editable: false,
      placeholder: "No content",
      limit: 20000,
    });

    return (
      <Box
        minH="80px"
        maxW="container.lg"
        w="100%"
        px="1"
        pt="2"
        pb="2"
        _focusWithin={{
          ".hover-buttons": {
            visibility: "visible",
            opacity: 1,
          },
        }}
        _hover={{
          ".hover-buttons": {
            visibility: "visible",
            opacity: 1,
          },
        }}>
        <Box
          px="3"
          py="4"
          bg="white"
          zIndex={1}
          ref={headingRef}
          willChange="transform"
          scrollMargin="calc(var(--chakra-space-top-bar) + 2rem)"
          id={sectionHTMLID}>
          <HStack bg="white" w="100%" justify="space-between">
            <Box flex={1}>
              <VStack align="start" gap="1">
                <Heading as="h3" fontSize="2.5rem" fontWeight="semibold">
                  {title}
                </Heading>
                <VStack align="start" gap="0">
                  <Text fontSize="xs" color="gray.600">
                    Owned by {responsibleUser.firstName}{" "}{responsibleUser.lastName}
                  </Text>
                  <Text fontSize="xs" color="gray.600">
                    Last updated on {formatDateOrIsoString(modified.date, "datetime-short")} by {modified.firstName}{" "}{modified.lastName}
                  </Text>
                </VStack>
              </VStack>
            </Box>
            <ButtonGroup
              size="sm"
              spacing={0}
              variant="ghost"
              visibility="hidden"
              opacity={0}
              className="hover-buttons">
              <Button
                leftIcon={<RiPencilLine size="16" />}
                onClick={onClickEdit}>
                Edit
              </Button>
              <Menu size="sm">
                <MenuButton as={IconButton} icon={<MoreMenuIcon />} />
                <MenuList>
                  <MenuItem icon={<RiEyeLine />}>Usage</MenuItem>
                  <MenuItem
                    onClick={onClickDelete}
                    color="red.700"
                    icon={<DeleteIcon />}>
                    Delete
                  </MenuItem>
                </MenuList>
              </Menu>
            </ButtonGroup>
          </HStack>
        </Box>

        <Box px="3" py="2" className="manual-editor">
          <EditorContent editor={readonlyEditor} />
        </Box>
      </Box>
    );
  },
);

export default ManualSection;
