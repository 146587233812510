import {
  Button,
  ButtonGroup,
  Icon,
  IconButton,
  Tooltip,
  chakra,
} from "@chakra-ui/react";
import { RiCloseLine } from "react-icons/ri";
import {
  WorkingFilter,
  getOpLabel,
} from "../../../../constants/frontEndFilterHelpers";
import Select from "../../../Select";
import FilterOpSelect from "./FilterOpSelect";
import FilterValueInputButton from "./FilterValueInputButton";
import { List } from "../../../../shared/v2/definitions/lists";
import { FilterOperationsType } from "../../../../shared/v2/helpers/filters/filterSchemas";

type Props = {
  filter: WorkingFilter;
  onClickDelete: () => void;
  lists: Record<string, List | null>;
  onUpdateFilter: (filter: WorkingFilter) => void;
};

export default function RecordsViewFilter({
  filter,
  onClickDelete,
  lists,
  onUpdateFilter,
}: Props) {
  const handleChangeOp = (op: FilterOperationsType | undefined) => {
    if (!op) {
      return;
    }
    // Check if we need to update the value
    if (op.valueType !== filter.op.valueType) {
      onUpdateFilter({
        ...filter,
        op,
        value: undefined,
      });
    } else {
      onUpdateFilter({
        ...filter,
        op,
      });
    }
  };

  const handleChangeValue = (
    value:
      | {
          label: string;
          value: string | number;
        }
      | {
          label: string;
          value: string | number;
        }[]
      | undefined,
  ) => {
    if (value) {
      onUpdateFilter({
        ...filter,
        value,
      });
    }
  };

  return (
    <ButtonGroup
      key={filter.ID}
      variant="outline"
      colorScheme="gray"
      size="sm"
      isAttached>
      <Button sx={{ cursor: "auto !important" }} _hover={{}}>
        {filter.field.label}
      </Button>
      <FilterOpSelect
        operationOptions={filter.field.availableOps}
        onChange={(newVal) => handleChangeOp(newVal)}
        isInActiveFilter
        value={filter.op}
      />
      {/* <Select
              options={[]}
              type="simple-button"
              simpleButtonProps={{
                borderLeftWidth: "1px",
                borderRightWidth: "1px",
                borderColor: "gray.200",
                color: "blackAlpha.700",
              }}
              value={{
                label: getOpLabel(filter.op.op),
                value: filter.op.op,
              }}
              placeholderText={getOpLabel(filter.op.op)}
            /> */}
      <FilterValueInputButton
        op={filter.op}
        field={filter.field}
        value={filter.value}
        lists={lists}
        onChange={handleChangeValue}
      />
      {/* <Select
        options={[]}
        type="simple-button"
        simpleButtonProps={{
          borderLeftWidth: "1px",
          borderRightWidth: "1px",
          borderColor: "gray.200",
          color: "blackAlpha.700",
        }}
        value={{
          label: getOpLabel(filter.op.op),
          value: filter.op.op,
        }}
        placeholderText={
          Array.isArray(filter.value) ? (
            filter.value.length >= 3 ? (
              <span>
                <chakra.span color="blackAlpha.700">any of:</chakra.span>{" "}
                {filter.value.length} options
              </span>
            ) : filter.value.length === 2 ? (
              <span>
                <chakra.span color="blackAlpha.700">any of:</chakra.span>{" "}
                {filter.value.map((v) => v.label).join(", ")}
              </span>
            ) : (
              filter.value[0]?.label
            )
          ) : (
            filter.value?.label
          )
        }
      /> */}
      <Tooltip label="Delete Filter">
        <IconButton
          onClick={onClickDelete}
          aria-label="Delete Filter"
          borderLeftWidth="1px"
          borderColor="gray.200"
          icon={<Icon boxSize="5" color="red.700" as={RiCloseLine} />}
        />
      </Tooltip>
    </ButtonGroup>
  );
}
